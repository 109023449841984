import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updatePerpetual } from "../../actions/perpetualActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import { Editor } from '@tinymce/tinymce-react';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import axios from "axios";
import keys from "../../actions/config";
const url = keys.baseUrl;
class PerpetualUpdateModal extends React.Component {
    constructor(props) {
        super(props);
         $("#update-perpetual-modal").find(".text-danger").hide();
       // console.log(this.props,'props records');
       // console.log(this.props.record,'fsdfnskdfsdjkf');
       // console.log(this.props.record.ticker_root,'fsdfnskdfsdjkf');
        this.state = {
            id: this.props.record._id,
            tickerroot             : this.props.record.ticker_root,
            hidden                 : this.props.record.ticker_root,
            initial_margin         : this.props.record.initial_margin,
            maint_margin           : this.props.record.maint_margin,
            interest_base_symbol   : this.props.record.interest_base_symbol,
            interest_quote_symbol  : this.props.record.interest_quote_symbol,
            funding_premium_symbol : this.props.record.funding_premium_symbol,
            risk_limit             : this.props.record.risk_limit,
            risk_step              : this.props.record.risk_step,
            minpriceincrement      : this.props.record.minpriceincrement,
            maxpriceincrement      : this.props.record.maxpriceincrement,
            contract_size          : this.props.record.contract_size,
            maxquantity            : this.props.record.maxquantity,
            minquantity            : this.props.record.minquantity,
            mark_price             : this.props.record.mark_price,
            leverage               : this.props.record.leverage,
            maker_rebate           : this.props.record.maker_rebate,
            dailyinterest           : this.props.record.dailyinterest,
            taker_fees             : this.props.record.taker_fees,
            lotsize                : this.props.record.lotsize,
            funding_interval       : this.props.record.funding_interval,
            first_currency         : { label: this.props.record.first_currency, value: this.props.record.first_currency },
            first_currency         : { label: this.props.record.second_currency, value: this.props.record.second_currency },
            errors: {},
            isEdit:false
        };
         this.handleChange = this.handleChange.bind(this);
         this.handleChange1 = this.handleChange1.bind(this);
    }

    styles = {
        option: (styles, {isFocused, isSelected}) => ({
            ...styles,
            color: "white",
            background: isFocused
                ? '#1d296a'
                : isSelected
                    ? '#113853'
                    : "#81c8f6",
            zIndex: 1
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          height: '52px',
          padding: '0 6px',
          backgroundColor: "#03081f",
          borderColor: '#81c8f6',
        borderRadius: 10,
        borderStyle: 'solid',
        borderWidth: '1px'
         
        }),
        control: (provided, state) => ({
          ...provided,
          height: '52px',
          borderRadius:10,
          backgroundColor: "#03081f",
          border:'none'
         
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height: '52px',
          position: 'absolute',
          right: 0,
          top: 0,
          color:'#fff' 
        }),    
        singleValue: (provided, state) => ({
          ...provided,
          color: "#fff"
        }),
        menuList: base => ({
            ...base,
            // kill the white space on first and last option
            padding: 0
          })
      };
     componentDidMount() {
            this.getData()  
        };

    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {
            this.setState({
                id: nextProps.record._id,
                tickerroot             : nextProps.record.tiker_root,
                hidden                 : nextProps.record.tiker_root,
                initial_margin         : nextProps.record.initial_margin,
                maint_margin           : nextProps.record.maint_margin,
                interest_base_symbol   : nextProps.record.interest_base_symbol,
                interest_quote_symbol  : nextProps.record.interest_quote_symbol,
                funding_premium_symbol : nextProps.record.funding_premium_symbol,
                risk_limit             : nextProps.record.risk_limit,
                risk_step              : nextProps.record.risk_step,
                minpriceincrement      : nextProps.record.minpriceincrement,
                maxpriceincrement      : nextProps.record.maxpriceincrement,
                contract_size          : nextProps.record.contract_size,
                minquantity            : nextProps.record.minquantity,
                maxquantity            : nextProps.record.maxquantity,
                mark_price             : nextProps.record.mark_price,
                leverage               : nextProps.record.leverage,
                maker_rebate           : nextProps.record.maker_rebate,
                dailyinterest           : nextProps.record.dailyinterest,
                taker_fees             : nextProps.record.taker_fees,
                lotsize                : nextProps.record.lotsize,
                funding_interval       : nextProps.record.funding_interval,
                first_currency         : {label: nextProps.record.first_currency, value: nextProps.record.first_currency },
                second_currency         : {label: nextProps.record.second_currency, value: nextProps.record.second_currency },
                selectedOption         : nextProps.record.first_currency,
                selectedOption1        : nextProps.record.second_currency,

            })
        }
        console.log("ISEDIT", this.state.isEdit)
        if (nextProps.errors && this.state.isEdit) {
             $("#update-perpetual-modal").find(".text-danger").show();
            this.setState({
                errors: nextProps.errors
            });
        }
        // else{
        //     this.setState({
        //         errors: ""
        //     });
        // }
        console.log(nextProps)
        if (nextProps.auth !== undefined
            && nextProps.auth.updateperpetual !== undefined
            && nextProps.auth.updateperpetual.data !== undefined
            && nextProps.auth.updateperpetual.data.message !== undefined
            && nextProps.auth.updateperpetual.data.success) {
            $('#update-perpetual-modal').modal('hide');
            toast(nextProps.auth.updateperpetual.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            nextProps.auth.updateperpetual = "";
        }
    }
      getData() {
           axios
           .get(url+"api/perpetual-data-first")
            .then(res => {   
                //console.log(res.data,'datassss');
                    var currencyarray =[]; 
                      res.data.map((item,i)=>{
                        const name = item.currencySymbol;
                        const value = item.currencySymbol;         
                        const obj = {'value':name, 'label':value};
                      currencyarray.push(obj);
                       });
                      //console.log(currencyarray,'currencyarray');
                       this.setState({ first_currency1:currencyarray,email_assigned:true});
                 })
            .catch()
        //console.log(this.props,'authget1');
    };
    onChange = e => {
         $("#update-perpetual-modal").find(".text-danger").show();
             this.setState({ [e.target.id]: e.target.value });
    };
     handleChange = selectedOption => {
        this.setState({first_currency : selectedOption});
        //console.log(`Option selected:`, selectedOption );
    };
    handleChange1 = selectedOption => {
        this.setState({ second_currency : selectedOption});
       // console.log(`Option selected:`, selectedOption );
    };

    onPerpetualUpdate = e => {
        e.preventDefault();
        
         $("#update-perpetual-modal").find(".text-danger").show();
        const newcontract = {
            _id                    : this.state.id,
            tickerroot             : this.state.first_currency.value + this.state.second_currency.value,
            initial_margin         : this.state.initial_margin,
            maint_margin           : this.state.maint_margin,
            interest_base_symbol   : this.state.interest_base_symbol,
            interest_quote_symbol  : this.state.interest_quote_symbol,
            funding_premium_symbol : this.state.funding_premium_symbol,
            risk_limit             : this.state.risk_limit,
            risk_step              : this.state.risk_step,
            minpriceincrement      : this.state.minpriceincrement,
            maxpriceincrement      : this.state.maxpriceincrement,
            contract_size          : this.state.contract_size,
            maxquantity            : this.state.maxquantity,
            minquantity            : this.state.minquantity,
            mark_price             : this.state.mark_price,
            leverage               : this.state.leverage,
            maker_rebate           : this.state.maker_rebate,
            dailyinterest           : this.state.dailyinterest,
            taker_fees             : this.state.taker_fees,
            lotsize                : this.state.lotsize,
            hidden                 : this.state.hidden,
            funding_interval       : this.state.funding_interval,
            second_currency        : this.state.second_currency.value,
            first_currency         : this.state.first_currency.value,
        };

        this.props.updatePerpetual(newcontract);
        this.setState({isEdit:true})
    };

    render() {
        const { errors,selectedOption,selectedOption1 } = this.state;
       /* const { selectedOption } = this.state.first_currency;
         const { selectedOption1 } = this.state.second_currency;*/
          const options1 = [
      { value: 'USD', label: 'USD' },
      { value: 'INR', label: 'INR' },
    ];
        
        return (
            <div>
                <div className="modal fade" id="update-perpetual-modal">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Perpetual</h4>
                                <button type="button" className="close" data-dismiss="modal" onClick={() => {this.setState({errors: {}, isEdit: false})}}>&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onPerpetualUpdate} id="update-perpetual">
                                 <input
                                        onChange={this.onChange}
                                        value={this.state.id}
                                        id="perpetual-update-id"
                                        type="text"
                                        className="d-none"/>
                                         <input
                                        onChange={this.onChange}
                                        value={this.state.hidden}
                                        id="hiiden"
                                        type="text"
                                        className="d-none"/>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="tickerroot">Ticker Root</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.tickerroot}
                                                id="tickerroot"
                                                type="text"
                                                error={errors.tickerroot}
                                                className={classnames("form-control", {
                                                    invalid: errors.tickerroot
                                                })} readOnly/>
                                            <span className="text-danger">{errors.tickerroot}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="initial_margin">Initial Margin</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.initial_margin}
                                                error={errors.initial_margin}
                                                id="initial_margin"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.initial_margin
                                                })}
                                            />
                                            <span className="text-danger">{errors.initial_margin}</span>
                                        </div>
                                    </div>

                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="maint_margin">Maintanence Margin (%)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.maint_margin}
                                                error={errors.maint_margin}
                                                id="maint_margin"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.maint_margin
                                                })}
                                            />
                                            <span className="text-danger">{errors.maint_margin}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="interest_base_symbol">Interest Base Symbol</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.interest_base_symbol}
                                                error={errors.interest_base_symbol}
                                                id="interest_base_symbol"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.interest_base_symbol
                                                })}
                                            />
                                            <span className="text-danger">{errors.interest_base_symbol}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="interest_quote_symbol">Interest Quote Symbol</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.interest_quote_symbol}
                                                error={errors.interest_quote_symbol}
                                                id="interest_quote_symbol"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.interest_quote_symbol
                                                })}
                                            />
                                            <span className="text-danger">{errors.interest_quote_symbol}</span>
                                        </div>
                                    </div>

                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="funding_premium_symbol">Funding Premium Symbol</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.funding_premium_symbol}
                                                error={errors.funding_premium_symbol}
                                                id="funding_premium_symbol"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.funding_premium_symbol
                                                })}
                                            />
                                            <span className="text-danger">{errors.funding_premium_symbol}</span>
                                        </div>
                                    </div>

                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="funding_interval">Funding Interval(in hours)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.funding_interval}
                                                error={errors.funding_interval}
                                                id="funding_interval"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.funding_interval
                                                })}
                                            />
                                            <span className="text-danger">{errors.funding_interval}</span>
                                        </div>
                                    </div>

                                      <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="leverage">Leverage</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.leverage}
                                                error={errors.leverage}
                                                id="leverage"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.leverage
                                                })}
                                            />
                                            <span className="text-danger">{errors.leverage}</span>
                                        </div>
                                    </div>

                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="taker_fees">Taker Fees (%)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.taker_fees}
                                                error={errors.taker_fees}
                                                id="taker_fees"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.taker_fees
                                                })}
                                            />
                                            <span className="text-danger">{errors.taker_fees}</span>
                                        </div>
                                    </div>


                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="maker_rebate">Maker Rebate (%)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.maker_rebate}
                                                error={errors.maker_rebate}
                                                id="maker_rebate"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.maker_rebate
                                                })}
                                            />
                                            <span className="text-danger">{errors.maker_rebate}</span>
                                        </div>
                                    </div>

                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="dailyinterest">Day Long Fee</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.dailyinterest}
                                                error={errors.dailyinterest}
                                                id="dailyinterest"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.dailyinterest
                                                })}
                                            />
                                            <span className="text-danger">{errors.dailyinterest}</span>
                                        </div>
                                    </div>

                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="risk_limit">Risk Limit</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.risk_limit}
                                                error={errors.risk_limit}
                                                id="risk_limit"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.risk_limit
                                                })}
                                            />
                                            <span className="text-danger">{errors.risk_limit}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="risk_step">Risk Step</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.risk_step}
                                                error={errors.risk_step}
                                                id="risk_step"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.risk_step
                                                })}
                                            />
                                            <span className="text-danger">{errors.risk_step}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="contract_size">Contract Size</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.contract_size}
                                                error={errors.contract_size}
                                                id="contract_size"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.contract_size
                                                })}
                                            />
                                            <span className="text-danger">{errors.contract_size}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="mark_price">Market Price</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.mark_price}
                                                error={errors.mark_price}
                                                id="mark_price"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.mark_price
                                                })}
                                            />
                                            <span className="text-danger">{errors.mark_price}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="minpriceincrement">Minimum Price Increment</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.minpriceincrement}
                                                error={errors.minpriceincrement}
                                                id="minpriceincrement"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.minpriceincrement
                                                })}
                                            />
                                            <span className="text-danger">{errors.minpriceincrement}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="maxpriceincrement">Max Price</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.maxpriceincrement}
                                                error={errors.maxpriceincrement}
                                                id="maxpriceincrement"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.maxpriceincrement
                                                })}
                                            />
                                            <span className="text-danger">{errors.maxpriceincrement}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="maxquantity">Max Order Quantity</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.maxquantity}
                                                error={errors.maxquantity}
                                                id="maxquantity"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.maxquantity
                                                })}
                                            />
                                            <span className="text-danger">{errors.maxquantity}</span>
                                        </div>
                                    </div>

                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="minquantity">Min Order Quantity</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.minquantity}
                                                error={errors.minquantity}
                                                id="minquantity"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.minquantity
                                                })}
                                            />
                                            <span className="text-danger">{errors.minquantity}</span>
                                        </div>
                                    </div>

                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="lotsize">Lot Size</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.lotsize}
                                                error={errors.lotsize}
                                                id="lotsize"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.lotsize
                                                })}
                                            />
                                            <span className="text-danger">{errors.lotsize}</span>
                                        </div>
                                    </div>
                                    
                                       <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="first_currency">First Currency</label>
                                        </div>
                                        <div className="col-md-9">
                                       {(this.state.first_currency)?
                                            <Select
                                             value={this.state.first_currency}
                                               defaultValue={{label: this.state.first_currency.label ,value: this.state.first_currency.label}}
                                                onChange={this.handleChange}
                                                options={this.state.first_currency1}
                                                styles={this.styles} className="border_blue_select"
                                            />:''}
                                            <span className="text-danger">{errors.first_currency}</span>
                                        </div>
                                    </div>

                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="second_currency">Second Currency</label>
                                        </div>
                                        <div className="col-md-9">
                                            {(this.state.second_currency)?
                                            <Select
                                             value={this.state.second_currency}
                                               defaultValue={{label: this.state.second_currency.label ,value: this.state.second_currency.label}}
                                                onChange={this.handleChange1}
                                                options={options1}
                                                styles={this.styles} className="border_blue_select"
                                            />:''}
                                            <span className="text-danger">{errors.second_currency}</span>
                                        </div>
                                    </div>

                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={()=> {this.setState({errors: {}, isEdit: false});}}>Close</button>
                                <button
                                    form="update-perpetual"
                                    type="submit"
                                    className="btn btn-primary">
                                    Update Contract
                                </button>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>

        )
    }
}

PerpetualUpdateModal.propTypes = {
    updatePerpetual: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updatePerpetual }
)(withRouter(PerpetualUpdateModal));
