import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addstaking } from "../../actions/staking";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import $ from "jquery";
import { Editor } from "@tinymce/tinymce-react";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import keys from "../../actions/config";
import Switch from "react-switch";

const url = keys.baseUrl;

const styles = {
  multiValue: (base, state) => {
    return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed
      ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
      : base;
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: 'none' } : base;
  },
};

const orderOptions = values => {
  return values.filter(v => v.isFixed).concat(values.filter(v => !v.isFixed));
};




class StakingAddModal extends React.Component {
  constructor() {
    super();
    this.state = {
      errors:{},
      currencyRef: "",
      minimum: 0,
      maximum: 0,
      redemptionPeriod: 0,
      flexibleApy: 0,
      flexible: false,
      records_currency_options: [],
      periodList: [{ days: "", apy: "" }],
    };
    this.switchAChange = this.switchAChange.bind(this);
  }

  styles = {
    option: (styles, {isFocused, isSelected}) => ({
      ...styles,
      color: "white",
      background: isFocused
          ? '#1d296a'
          : isSelected
              ? '#113853'
              : "#81c8f6",
      zIndex: 1
  }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '52px',
      padding: '0 6px',
      backgroundColor: "#03081f",
      borderColor: '#81c8f6',
    borderRadius: 10,
    borderStyle: 'solid',
    borderWidth: '1px'
     
    }),
    control: (provided, state) => ({
      ...provided,
      height: '52px',
      borderRadius:10,
      backgroundColor: "#03081f",
      border:'none'
     
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '52px',
      position: 'absolute',
      right: 0,
      top: 0,
      color:'#fff' 
    }),    
    singleValue: (provided, state) => ({
      ...provided,
      color: "#fff"
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0
    })
  };

  switchAChange(checked) {
    this.setState({ flexible : checked });
  }






  // handle input change
  handleInputChange = (e, index) => {
    var periodList = this.state.periodList;
    const { name, value } = e.target;
    const list = [...periodList];
    list[index][name] = value;
    console.log('list : ',list);
    this.setState({ periodList: list });
  };

  // handle click event of the Remove button
  handleRemoveClick = index => {
    var periodList = this.state.periodList;
    const list = [...periodList];
    list.splice(index, 1);
    console.log('list : ',list);
    this.setState({ periodList: list });
  };

  // handle click event of the Add button
  handleAddClick = () => {
    var periodList = this.state.periodList;
    const list = [...periodList];
    list.push({ days: "", apy: "" });
    console.log('list : ',list);
    this.setState({ periodList: list });
  };







  handleChange = (optionsTerms) => {
    this.setState({ currencyRef: optionsTerms.value });
  };
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  getCurrencyData() {
    axios
    .post(url+"api/currency")
    .then(res => {
        if(res.data.length==0){
          this.setState({ records_currency_options: []});
        } else {
          var records_currency_options = [];
          for (var i = 0; i < res.data.length; i++) {
            var curRep = res.data[i];
            var _id = curRep._id;
            var currencyName = curRep.currencyName;
            var currencySymbol = curRep.currencySymbol;
            records_currency_options.push({value:_id,label:currencySymbol});
            if(res.data.length-1 == i){
              this.setState({ records_currency_options: records_currency_options});
            }
          }
        }
    })
    .catch()
  }
  componentDidMount() {
      this.getCurrencyData()
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      $("#add-staking-modal").find(".text-danger").show();
      this.setState({
        errors: nextProps.errors,
      });
    } else {
      this.setState({
        errors: "",
      });
    }
    //console.log(nextProps.auth.stakingadd.data,'shdbjksfcgdsyfvfdsuy');
    if (
      nextProps.auth !== undefined &&
      nextProps.auth.stakingadd !== undefined &&
      nextProps.auth.stakingadd.data !== undefined &&
      nextProps.auth.stakingadd.data.message !== undefined
    ) {
      $("#add-staking-modal").modal("hide");
      this.setState({
        errors: "",
      });
      toast(nextProps.auth.stakingadd.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      nextProps.auth.stakingadd = undefined;
      this.setState({
        errors: "",
      });
    }
  }

  onStakingAdd = (e) => {
    e.preventDefault();
    console.log("this.state : ",this.state);

    const newStaking = {
      currencyRef: this.state.currencyRef,
      minimum: this.state.minimum,
      maximum: this.state.maximum,
      redemptionPeriod: this.state.redemptionPeriod,
      flexible: this.state.flexible,
      flexibleApy: this.state.flexibleApy,
      periodList: this.state.periodList
    };
    console.log("newStaking : ",newStaking);
    this.props.addstaking(newStaking);
  };

  render() {
    const { errors,periodList } = this.state;
    return (
      <div>
        <div className="modal fade" id="add-staking-modal" data-reset="true">
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Staking</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form
                  noValidate
                  onSubmit={this.onStakingAdd}
                  id="add-staking">

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="currencyRef">Staking</label>
                    </div>
                    <div className="col-md-9">
                      <Select
                        onChange={this.handleChange}
                        options={this.state.records_currency_options}
                        label="Single select"
                        styles={this.styles} className="border_blue_select"
                      />
                      <span className="text-danger">{errors.currencyRef}</span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="minimum">Minimum Amount</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.minimum}
                        error={errors.minimum}
                        id="minimum"
                        type="text"
                        className={classnames("form-control", {
                          invalid: errors.minimum,
                        })}
                      />
                      <span className="text-danger">{errors.minimum}</span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="minimum">Maximum Amount</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.maximum}
                        error={errors.maximum}
                        id="maximum"
                        type="text"
                        className={classnames("form-control", {
                          invalid: errors.maximum,
                        })}
                      />
                      <span className="text-danger">{errors.maximum}</span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="minimum">Redemption Period</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.redemptionPeriod}
                        error={errors.redemptionPeriod}
                        id="redemptionPeriod"
                        type="text"
                        className={classnames("form-control", {
                          invalid: errors.redemptionPeriod,
                        })}
                      />
                      <span className="text-danger">{errors.redemptionPeriod}</span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="flexible">
                        <span>Flexible</span>
                      </label>
                    </div>
                    <div className="col-md-9">
                      <Switch onChange={this.switchAChange} checked={this.state.flexible} />
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="flexibleApy">flexibleApy</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.flexibleApy}
                        error={errors.flexibleApy}
                        id="flexibleApy"
                        type="text"
                        className={classnames("form-control", {
                          invalid: errors.flexibleApy,
                        })}
                      />
                      <span className="text-danger">{errors.redemptionPeriod}</span>
                    </div>
                  </div>

                  {
                    this.state.periodList.map((x, i) => {
                      return (
                        <div className="row mt-2">
                          <div className="col-md-3">
                            <label htmlFor="minimum">Period {i+1}</label>
                          </div>
                          <div className="box row col-md-9 pading_remove_mobil">
                            <div className="col-md-5 col-lg-4 mb-3">
                              <input
                                name="days"
                                placeholder="Enter Days"
                                value={x.days}
                                onChange={e => this.handleInputChange(e, i)}
                                className={classnames("form-control")}
                              />
                            </div>
                            <div className="col-md-5 col-lg-4 mb-3 pading_remove_mobil">
                              <input
                                name="apy"
                                placeholder="Enter apy"
                                value={x.apy}
                                onChange={e => this.handleInputChange(e, i)}
                                className={classnames("form-control")}
                              />
                            </div>
                            <div className="btn-box col-md-3">
                              {
                                periodList.length !== 1 &&<button
                                className="btn btn-primary"
                                onClick={() => this.handleRemoveClick(i)}>Remove</button>
                              }
                              {
                                periodList.length - 1 === i && <button type="button" className="btn btn-primary ml-2" onClick={this.handleAddClick}>Add</button>
                              }
                            </div>
                          </div>
                        </div>
                        )
                      })
                     }

                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  form="add-staking"
                  type="submit"
                  className="btn btn-primary">
                  Add staking
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

StakingAddModal.propTypes = {
  addstaking: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { addstaking })(
  withRouter(StakingAddModal)
);
