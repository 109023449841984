import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import classnames from "classnames";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import { updateChangepassword } from "../../actions/userActions";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer} from "react-toastify";
import keys from "../../actions/config";
import { withRouter,Link } from "react-router-dom";
const url = keys.baseUrl;
class Changepassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            _id : "",
            oldpassword: "",
            password: "",
            password2: "",
            errors: {},
              otp  : "",
              otpnew:"",
              name: "",
              email: "",
              oldshowPassword:false,
              newshowPassword:false,
              confirmshowPassword:false
        };
    }

    componentDidMount() {
      this.getData()
    };

    getData() {
         if (this.props.auth !== undefined && this.props.auth.user !== undefined) {
            const id = this.props.auth.user.id;
            axios
            .get(url+"api/userget/"+id)
            .then(res => {
                console.log(res,'reponse of user');
                this.setState(res.data);

            })
            .catch()
        }
        console.log(this.props.auth);
    }


     componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.Changepassword !== undefined
            && nextProps.auth.Changepassword.data !== undefined
            && nextProps.auth.Changepassword.data.message !== undefined) {
            toast(nextProps.auth.Changepassword.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            nextProps.auth.Changepassword = undefined;
        }
    }
    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };
    onsendOtp = e => {
        console.log("THE SEND OTP")
        e.preventDefault();
      // if(typeof this.state.phone != "undefined" && this.state.phone!=""){
        const emaildata = {
            _id: this.props.auth.user.id,
          // email: this.state.email
        };
        console.log("emaildata",emaildata);
        axios
            .post(url+"api/sendotppasswordchange",emaildata)
            .then(res => {
              if(res.data.success){
                toast(res.data.message, {
                position: toast.POSITION.TOP_CENTER
                });
                this.setState({errors: ""})
              }else{
                toast(res.data.message, {
                position: toast.POSITION.TOP_CENTER
                });
              }
            })
            .catch();

     }

     onChangepasswordUpdate = e => {
        e.preventDefault();
        const updatechangepassword = {
            _id: this.props.auth.user.id,
            oldpassword: this.state.oldpassword,
            password: this.state.password,
            password2: this.state.password2,
            otpnew:this.state.otpnew
        };
        this.props.updateChangepassword(updatechangepassword);
    };

    render() {
        const { errors } = this.state;
        const { otp, newPassword, oldshowPassword,newshowPassword,confirmshowPassword,oldPassword, confirmPassword } = this.state;
        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">


                            <h3 className="mt-2 text-secondary">Change Password</h3>


                            <form noValidate onSubmit={this.onsendOtp} id="otp-form" className="mt-5">
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="password">Email</label>
                                </div>
                                <div className="col-md-6">

                                <div class="input-group input_grp_btn">
  <input type="text"  value={this.state.email} className={classnames("form-control", {
                                            invalid: errors.email
                                        })} aria-label="Recipient's username" aria-describedby="basic-addon2" />
  <div class="input-group-append">
    <span class="input-group-text" id="basic-addon2"> <button  form="otp-form" className="btn btn-primary">Send OTP</button></span>
  </div>
</div>

                               
                               

                                </div>
                            </div>

                        </form>


                            <form noValidate onSubmit={this.onChangepasswordUpdate} id="update-Changepassword">

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Otp</label>
                                </div>
                                <div className="col-md-6">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.otpnew}
                                        id="otpnew"
                                        type="text"
                                        error={errors.otpnew}
                                        className={classnames("form-control", {
                                            invalid: errors.otpnew
                                        })}/>
                                    <span className="text-danger">{errors.otpnew}</span>
                                </div>
                            </div>



                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Old Password</label>
                                        </div>
                                        <div className="col-md-6">
                                        <div class="input-group input_grp_width">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.oldpassword}
                                                id="oldpassword"
                                                type={oldshowPassword ? "text":"password"}
                                                error={errors.oldpassword}
                                                className={classnames("form-control", {
                                                    invalid: errors.oldpassword
                                                })}/>
                                                  <div className="input-group-append">
                                                      <Link
                            className="input-group-text no_underline" id="basic-addon2"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({ oldshowPassword: !oldshowPassword });
                            }}
                            >
                               <i className={oldshowPassword && oldshowPassword ?"fa fa-eye cur_pointer_icon":"fa fa-eye-slash cur_pointer_icon"}></i>
                            </Link>
                          </div>
                          </div>
                          
                                            <span className="text-danger">{errors.oldpassword}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password">New Password</label>
                                        </div>
                                        <div className="col-md-6">
                                        <div class="input-group input_grp_width">
                                           <input
                                                onChange={this.onChange}
                                                value={this.state.password}
                                                id="password"
                                                type={newshowPassword ? "text":"password"}
                                                error={errors.password}
                                                className={classnames("form-control", {
                                                    invalid: errors.password
                                                })}/>
                                                         <div className="input-group-append">
                                                      <Link
                            className="input-group-text no_underline" id="basic-addon2"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({ newshowPassword: !newshowPassword });
                            }}
                            >
                               <i className={newshowPassword && newshowPassword ?"fa fa-eye cur_pointer_icon":"fa fa-eye-slash cur_pointer_icon"}></i>
                            </Link>
                          </div>
                                                </div>
                                            <span className="text-danger">{errors.password}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Confirm Password</label>
                                        </div>
                                        <div className="col-md-6">
                                        <div class="input-group input_grp_width">
                                        <input
                                                onChange={this.onChange}
                                                value={this.state.password2}
                                                id="password2"
                                                type={confirmshowPassword ? "text":"password"}
                                                error={errors.password2}
                                                className={classnames("form-control", {
                                                    invalid: errors.password2
                                                })}/>
                                                                   <div className="input-group-append">
                                                      <Link
                            className="input-group-text no_underline" id="basic-addon2"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({ confirmshowPassword: !confirmshowPassword });
                            }}
                            >
                               <i className={confirmshowPassword && confirmshowPassword ?"fa fa-eye cur_pointer_icon":"fa fa-eye-slash cur_pointer_icon"}></i>
                            </Link>
                          </div>
                                                </div>
                                            <span className="text-danger">{errors.password2}</span>
                                        </div>
                                    </div>
                                </form>
                                    <br />
                                <button
                                    form="update-Changepassword"
                                    type="submit"
                                    className="btn btn-primary">
                                    Update Password
                                </button>
                         </div>
                    </div>
                    <ToastContainer/>
                </div>
            </div>
        );
    }

}

Changepassword.propTypes = {
    updateChangepassword: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateChangepassword }
)(withRouter(Changepassword));
