import React, { Component } from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import {logoutUser} from "../../actions/authActions";
import {Link, NavLink} from "react-router-dom";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import { Scrollbars } from 'react-custom-scrollbars';
import { Alert } from "bootstrap";

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show:(window.innerWidth>768)?true:false
        }
    }
    onLogoutClick = e => {
        e.preventDefault();
        this.props.logoutUser();
    };

    componentDidMount() {

        if(window.innerWidth>991)
        {
            // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
            if(document.querySelectorAll(".list-group-item-action.active").length>0)
            {           
                 var pagelinkofset = document.querySelectorAll(".list-group-item-action.active")[0].offsetTop;
            document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset);

            }

        }
        else
        {
            // alert(2)
            if(document.querySelectorAll(".list-group-item-action.active").length>0)
            {
                // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
                if(document.querySelectorAll(".list-group-item-action.active").length>0)
                {  
                var pagelinkofset = document.querySelectorAll(".list-group-item-action.active")[0].offsetTop;
                document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset);
                }
            }
            
        }

       
        // window.addEventListener("resize", this.changestate);
     
       
    }

    // componentWillUnmount() {
    //     window.removeEventListener("resize", this.changestate);
    // }

    changestate= e => {
        this.setState({show:(this.state.show)?false:true});
        document.getElementsByTagName('body')[0].classList.toggle("shrink_whole");
        // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
        // var pagelinkofset = document.querySelectorAll(".list-group-item-action.active")[0].offsetTop;
        // document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset);
    }

    render() {
        const { user } = this.props.auth.user;
        console.log(this.props.auth.user,'fsdlfsdlkfjsldkjflskdjflsdf');
        return (
        <div>
            <button className="btn" onClick={this.changestate} id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>
            { this.state.show?
            <div className="border-right sidebar_whole" >
                <div className="list-group list-group-flush">
                    <div className="scrollbar_sidebar" id="scrollbar_sidebar_id">
                    {/* <Scrollbars style={{ width: 250, height:'calc(100vh - 65px)' }} renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}> */}
                        {
                        (this.props.auth.user.moderator=='1')?
                        <div>
                            <NavLink to="/users" className="list-group-item list-group-item-action">Users</NavLink>
                            <NavLink to="/chat" className="list-group-item list-group-item-action">Chat</NavLink>
                        </div> :
                        <div>
                            <NavLink to="/dashboard" className="list-group-item list-group-item-action">Dashboard</NavLink>
                            <NavLink to="/users" className="list-group-item list-group-item-action">Users</NavLink>
                            <NavLink to="/Userbalance" className="list-group-item list-group-item-action">User Balance</NavLink>
                            <NavLink to="/kyc" className="list-group-item list-group-item-action">KYC</NavLink>
                            {/* <NavLink to="/bonus" className="list-group-item list-group-item-action">Bonus</NavLink> */}
                            <NavLink to="/deposit" className="list-group-item list-group-item-action">Deposit List</NavLink>
                            <NavLink to="/withdraw" className="list-group-item list-group-item-action">Withdraw List</NavLink>
                            {/*<Link to="/staking" className="list-group-item list-group-item-action">Staking</Link>
                            <Link to="/launchpad" className="list-group-item list-group-item-action">Launchpad</Link>*/}
                            
                            {/*<Link to="/feesettings" className="list-group-item list-group-item-action">Fee Settings</Link>*/}

                            <NavLink to="/currency" className="list-group-item list-group-item-action">Currency</NavLink>

                            <NavLink to="/Pairmanagement" className="list-group-item list-group-item-action">Trade Pair</NavLink>
                            {/*<Link to="/Tradingbot" className="list-group-item list-group-item-action">Trading bot</Link>*/}
                            <NavLink to="/orderhistory" className="list-group-item list-group-item-action">Spot Order History</NavLink>
                            <NavLink to="/tradehistory" className="list-group-item list-group-item-action">Spot Trade History</NavLink>

                            <NavLink to="/perpetual" className="list-group-item list-group-item-action">Perpetual contract</NavLink>
                            <NavLink to="/perpetual-Order-History" className="list-group-item list-group-item-action">Perpetual Order History</NavLink>
                            <NavLink to="/perpetual-Trade-History" className="list-group-item list-group-item-action">Perpetual Trade History</NavLink>
                            <NavLink to="/liquidated" className="list-group-item list-group-item-action">Liquidated List</NavLink>

                            <NavLink to="/copytrade/settings" className="list-group-item list-group-item-action">Copy Trade : Settings</NavLink>
                            <NavLink to="/copytrade/applications" className="list-group-item list-group-item-action">Copy Trade : Applications</NavLink>
                            {/* <Link to="/closedpositions" className="list-group-item list-group-item-action">Closed positions</Link> */}

                            {/* <Link to="/chat" className="list-group-item list-group-item-action">Chat</Link> */}
                            <NavLink to="/emailtemplates" className="list-group-item list-group-item-action">Email Templates</NavLink>
                            <NavLink to="/cms" className="list-group-item list-group-item-action">CMS Pages</NavLink>
                            <NavLink to="/commissionhistory" className="list-group-item list-group-item-action">Commission History</NavLink>
                            <NavLink to="/faq" className="list-group-item list-group-item-action">FAQ</NavLink>
                            <NavLink to="/contactus" className="list-group-item list-group-item-action">Contact Us</NavLink>
                            <NavLink to="/newsletter" className="list-group-item list-group-item-action">Newsletter</NavLink>
                            <NavLink to="/support" className="list-group-item list-group-item-action" id="support_menu">Support</NavLink>

                            {/* <NavLink to="/category" className="list-group-item list-group-item-action">Blog Category</NavLink> */}
                            <NavLink to="/blocklist" className="list-group-item list-group-item-action">Block List</NavLink>
                            {/* <NavLink to="/HelpCentreCategory" className="list-group-item list-group-item-action">Help Centre Category</NavLink>
                            <NavLink to="/subcategory" className="list-group-item list-group-item-action">Help Center Sub Category</NavLink>
                            <NavLink to="/HelpCentreArticle" className="list-group-item list-group-item-action">Help Center Article</NavLink> */}

                            {/* <NavLink to="/HelpCentreArticle" className="list-group-item list-group-item-action">Blog</NavLink> */}
                        </div>
                        }
                        </div>
                    {/* </Scrollbars> */}
                </div>
            </div>:'' }
        </div>
        );
    }
}

Sidebar.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { logoutUser }
)(Sidebar);
