import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addLaunchpad } from "../../actions/LaunchpadActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import { Editor } from '@tinymce/tinymce-react';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class LaunchpadAddModal extends React.Component {

    constructor() {
        super();
        this.state = {
            tokenname: "",
            symbol: "",
            availablecurrency:"",
            price: "",
            minAmt: "",
            discount:"",
            availablesale:"",
            maxsupply:"",
            industry:"",
            website:"",
            content:"",
            curimage:null,
            attachment:"",
            imageerror:"",
            curimagesize:0,
            startDate:new Date(),
            endDate:new Date(),
            errors: {},
        };
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChange1 = this.handleChange1.bind(this);

    }
    handleChange(date) {
     this.setState({
     startDate: date
     })
      
     }
 handleChange1(date1) {
  
        this.setState({
     endDate: date1
     })
     }
    componentWillReceiveProps(nextProps) {
        // console.log("helooooooooooooooooooooooooooooooooooo",nextProps.auth);
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        console.log("retJsonObj",nextProps.auth)
        if (nextProps.auth !== undefined
            && nextProps.auth.Contactus !== undefined
            && nextProps.auth.Contactus.data !== undefined
            && nextProps.auth.Contactus.data.retMsg !== undefined) {
            $('#add-launch-modal').modal('hide');
            toast(nextProps.auth.Contactus.data.retMsg, {
                position: toast.POSITION.TOP_CENTER
            });
            nextProps.auth.newuser = undefined;
        }
    }
       handleEditorChange(content, editor) {
       this.setState({ content });
     }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };
 handleChangeefile = (event) => {
if(event.target.files[0]){
    var filesize= event.target.files[0].size
    this.setState({
      curimage: URL.createObjectURL(event.target.files[0]),
      attachment: event.target.files[0],
    });
}

  };
    onLaunchpadAdd = e => {
        e.preventDefault();
console.log("@@@@@@@@@",this.state)
  const addLaunchpad1 = {
            tokenname: this.state.tokenname,
            symbol: this.state.symbol,
            availablecurrency:this.state.availablecurrency,
            email: this.state.email,
            user_phone_number: this.state.user_phone_number,
            picture: this.state.picture
        };

     const data = new FormData();
      data.append("startdate", this.state.startDate.toISOString());
      data.append("endDate", this.state.endDate.toISOString());
      data.append("tokenname", this.state.tokenname);
      data.append("symbol", this.state.symbol);
      data.append("availablecurrency", this.state.availablecurrency);
      data.append("price", this.state.price);
      data.append("minAmt", this.state.minAmt);
      data.append("discount", this.state.discount);
      data.append("availablesale", this.state.availablesale);
      data.append("maxsupply", this.state.maxsupply);
      data.append("industry", this.state.industry);
      data.append("website", this.state.website);
      data.append("content", this.state.content);
      data.append("file1", this.state.attachment);
      console.log("****************",data);
      this.props.addLaunchpad(data);

     
    };
    setStartDate(date){
        console.log("date",date)

    }

    render() {
        const { errors } = this.state;
        console.log("errors",errors)
        return (
            <div>
                <div className="modal fade" id="add-launch-modal" data-reset="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add Launchpad Details</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onLaunchpadAdd} id="add-lauchpad" >
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="tokenname">Token Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.tokenname}
                                                id="tokenname"
                                                type="text"
                                                error={errors.tokenname}
                                                className={classnames("form-control", {
                                                    invalid: errors.tokenname
                                                })}/>
                                            <span className="text-danger">{errors.tokenname}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="symbol">Token Symbol</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.symbol}
                                                error={errors.symbol}
                                                id="symbol"
                                                type="symbol"
                                                className={classnames("form-control", {
                                                    invalid: errors.symbol
                                                })}
                                            />
                                            <span className="text-danger">{errors.symbol}</span>
                                        </div>
                                    </div>
                                 <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="symbol">Available Currency</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.availablecurrency}
                                                error={errors.availablecurrency}
                                                id="availablecurrency"
                                                type="availablecurrency"
                                                className={classnames("form-control", {
                                                    invalid: errors.availablecurrency
                                                })}
                                            />
                                            <span className="text-danger">{errors.availablecurrency}</span>
                                        </div>
                                    </div>
                                        <div className="row mt-2">
                                  <div className="col-md-3">
                                  <label htmlFor="minimum">Token ICON</label>
                                  </div>
                                  <div className="col-md-9">
                                  <label class="custom-file-upload">
                                  <input
                                        type="file"
                                        accept="image/x-png,image/gif,image/jpeg"
                                        onChange={this.handleChangeefile}
                                        name="file1"
                                        id="exampleInputFile"
                                        aria-describedby="fileHelp"
                                      />
                                      Choose File
                                      </label>
                                      <span className="text-danger">{this.state.imageerror}</span>
                                     
                                  </div>
                                </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="price">Token Launch Price</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.price}
                                                error={errors.price}
                                                id="price"
                                                type="price"
                                                className={classnames("form-control", {
                                                    invalid: errors.price
                                                })}
                                            />
                                            <span className="text-danger">{errors.price}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Minimum Purchase Amount</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.minAmt}
                                                id="minAmt"
                                                type="minAmt"
                                                className={classnames("form-control", {
                                                    invalid: errors.minAmt
                                                })}
                                            />
                                            <span className="text-danger">{errors.minAmt}</span>
                                        </div>
                                    </div>
                                      <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Discount</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.discount}
                                                id="discount"
                                                type="discount"
                                                className={classnames("form-control", {
                                                    invalid: errors.discount
                                                })}
                                            />
                                            <span className="text-danger">{errors.discount}</span>
                                        </div>
                                    </div>
                                       <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Token Available to Sale</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.availablesale}
                                                id="availablesale"
                                                type="availablesale"
                                                className={classnames("form-control", {
                                                    invalid: errors.availablesale
                                                })}
                                            />
                                            <span className="text-danger">{errors.availablesale}</span>
                                        </div>
                                    </div>
                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Token Max Supply</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.maxsupply}
                                                id="maxsupply"
                                                type="maxsupply"
                                                className={classnames("form-control", {
                                                    invalid: errors.maxsupply
                                                })}
                                            />
                                            <span className="text-danger">{errors.maxsupply}</span>
                                        </div>
                                    </div>
                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Industry</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.industry}
                                                id="industry"
                                                type="industry"
                                                className={classnames("form-control", {
                                                    invalid: errors.industry
                                                })}
                                            />
                                            <span className="text-danger">{errors.industry}</span>
                                        </div>
                                    </div>
                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Website</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.website}
                                                id="website"
                                                type="website"
                                                className={classnames("form-control", {
                                                    invalid: errors.website
                                                })}
                                            />
                                            <span className="text-danger">{errors.website}</span>
                                        </div>
                                    </div>
                                      <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Start Date</label>
                                        </div>
                                        <div className="col-md-9">
                                         <DatePicker
                                          selected={ this.state.startDate }
                                          onChange={ this.handleChange }
                                          dateFormat="MMMM d, yyyy"
                                          className={classnames("form-control", {
                                                    invalid: errors.startDate
                                                })}
                                          />
                                            <span className="text-danger">{errors.startdate}</span>
                                        </div>
                                    </div>
                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">End Date</label>
                                        </div>
                                        <div className="col-md-9">
                                         <DatePicker
                                          selected={ this.state.endDate }
                                          onChange={ this.handleChange1 }
                                          dateFormat="MMMM d, yyyy"
                                          className={classnames("form-control", {
                                                    invalid: errors.endDate
                                                })}
                                          />
                                            <span className="text-danger">{errors.endDate}</span>
                                        </div>
                                    </div>
                                       <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="content">Content</label>
                                        </div>
                                        <div className="col-md-9">
                                        <Editor apiKey='5vk89nvvi2zckrb2lp2ctyyolewhq1v3pzdiwb7at68h40a5'
                                           initialValue="<p>This is the initial content of the editor</p>"
                                           value={this.state.content} onEditorChange={this.handleEditorChange}
                                           init={{
                                             height: 500,
                                             menubar: false,
                                             plugins: [
                                               'advlist autolink lists link image charmap print preview anchor',
                                               'searchreplace visualblocks code fullscreen',
                                               'insertdatetime media table paste code help wordcount'
                                             ],
                                             toolbar:
                                               'undo redo code | formatselect | bold italic backcolor | \
                                               alignleft aligncenter alignright alignjustify | \
                                               bullist numlist outdent indent | removeformat | help'
                                           }}
                                         />
                                            <span className="text-danger">{errors.content}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="add-lauchpad"
                                    type="submit"
                                    className="btn btn-primary">
                                    Add Launchpad
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

LaunchpadAddModal.propTypes = {
    addLaunchpad: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addLaunchpad}
)(withRouter(LaunchpadAddModal));
