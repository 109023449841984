import axios from "axios";
import {
    GET_ERRORS,
    STAKING_ADD,
    STAKING_UPDATE,
} from "./types";
import keys from "./config";
const url = keys.baseUrl;

export const addstaking = (stakingData) => dispatch => {
    axios
        .post(url+"api/staking_add", stakingData)
        .then(res =>
            dispatch({
                type: STAKING_ADD,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};


export const stakingupdate = (faqData) => dispatch => {
    axios
        .post(url+"api/staking_update", faqData)
        .then(res =>
            dispatch({
                type: STAKING_UPDATE,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};
