import React, { Component, Fragment } from "react";
import Navbar from "../../partials/Navbar";
import Sidebar from "../../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import CmsAddModal from "../../partials/CmsAddModal";
import CmsUpdateModal from "../../partials/CmsUpdateModal";
import { toast, ToastContainer} from "react-toastify";
import keys from "../../../actions/config";
const url = keys.baseUrl;
class CopyTradeApplications extends Component {
    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "userId",
                text: "User",
                className: "identifier",
                align: "left",
                sortable: true,
                cell: record => {
                    if(record?.userId?.name)
                        return record?.userId?.name;
                    else
                        return record?.userId?.email.split('@')[0].substring(0, 10);
                }
            },
            {
                key: "approve",
                text: "Approval",
                className: "approval",
                align: "left",
                sortable: true,
                cell: record => {
                    if(record?.approve == 1) {
                        return 'Pending';
                    } else if(record?.approve==2) {
                        return 'Approved';
                    } else {
                        return 'Rejected';
                    }
                }
            },
            {
                key: "createdDate",
                text: "Created Date",
                className: "date",
                align: "left",
                sortable: true,
                cell: record => {
                    var data1 = new Date(record?.createdDate);
                    return data1.getFullYear() + '-' + (data1.getMonth() + 1) + '-' + data1.getDate() + ' ' + data1.getHours() + ':' + data1.getMinutes() + ':' + data1.getSeconds();
                }

            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 200,
                align: "left",
                sortable: false,
                cell: record => {
                    if(record?.approve==2) {
                        return (
                            <Fragment>
                                <button className="btn btn-primary btn-sm mr-1">Approved</button>
                                <button className="btn btn-danger btn-sm" onClick={() => this.changestatus(record,3)}>Disable</button>
                            </Fragment>
                        );
                    } else if(record?.approve==3){
                       return (
                            <Fragment>
                                <button className="btn btn-primary btn-sm mr-1" onClick={() => this.changestatus(record,2)}>Accept</button>
                                <button className="btn btn-danger btn-sm">Rejected</button>
                            </Fragment>
                        ); 
                   }
                   else{
                    return (
                        <Fragment>
                            <button className="btn btn-primary btn-sm mr-1" onClick={() => this.changestatus(record,2)}>Accept</button>
                            <button className="btn btn-danger btn-sm" onClick={() => this.changestatus(record,3)}>Reject</button>
                        </Fragment>
                    ); 
                   }
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Cms",
            no_data_text: 'No Application found!',
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: []
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData() {
        axios.post(url+"api/v1/copytrade/request/data").then(res => {
            this.setState({ records: res.data.data});
            console.log(res.data.data,'resssss');
        }).catch();
    }

    changestatus(record, action) {
        let data = {
            userid: record?.userId?._id,
            action: action
        };
        axios.post(url+"api/v1/copytrade/request/acceptorreject", data).then(res => {
            console.log(res,'reschange');
            if (res.status === 200) {
                toast(res.data.toast.message, {
                   position: toast.POSITION.TOP_CENTER,
                })
                this.getData();
            }
        }).catch();
        // this.getData();
    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
        return (
            <div>
                <Navbar/>
                {console.log('edit',this.config)}
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h3 className="mt-2 text-secondary">Copy Trade Applications List</h3>
                            <div className="resonsive_table">
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                               className="table table-bordered table-striped copy_trade_table"

                            />
                            </div>
                        </div>
                    </div>
                    <ToastContainer/>
                </div>
            </div>
        );
    }

}

CopyTradeApplications.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(CopyTradeApplications);
