import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateLaunchpad } from "../../actions/LaunchpadActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import { Editor } from '@tinymce/tinymce-react';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
class LaunchpadUpdateModal extends React.Component {
    constructor(props) {
        super(props);
         $("#update-launchpad-modal").find(".text-danger").hide();
         this.state = {
            id: this.props.record._id,
            tokenname: this.props.record.tokenname,
            symbol: this.props.record.symbol,
            availablecurrency: this.props.record.availablecurrency,
            price: this.props.record.price,
            minAmt: this.props.record.minAmt,
            discount: this.props.record.discount,
            availablesale: this.props.record.availablesale,
            maxsupply: this.props.record.maxsupply,
            industry: this.props.record.industry,
            website: this.props.record.website,
            curimage: this.props.record.curimage,
            content: this.props.record.content,
            startDate:new Date(),
            endDate:new Date(),
             errors: {},
         };
         this.handleEditorChange = this.handleEditorChange.bind(this);
         this.handleChange = this.handleChange.bind(this);
         this.handleChange1 = this.handleChange1.bind(this);
    }
    handleChange(date) {
     this.setState({
     startDate: date
     })
      
     }
    handleChange1(date1) {
  
        this.setState({
     endDate: date1
     })
     }
    handleEditorChange(content, editor) {
       this.setState({ content });
     }

    componentWillReceiveProps(nextProps) {
        console.log("!!!!!!!!!!!!",nextProps)
        if (nextProps.record) {
            this.setState({
                id: nextProps.record._id,
                tokenname: nextProps.record.tokenname,
                symbol: nextProps.record.symbol,
                availablecurrency:nextProps.record.availablecurrency,
                price:nextProps.record.price,
                minAmt: nextProps.record.minAmt,
                discount: nextProps.record.discount,
                availablesale: nextProps.record.availablesale,
                maxsupply: nextProps.record.maxsupply,
                industry: nextProps.record.industry,
                website: nextProps.record.website,
                curimage: nextProps.record.curimage,
                content: nextProps.record.content,
                startdate:nextProps.record.startDate,
                enddate:nextProps.record.enddate
            })
        }
        if (nextProps.errors) {
             $("#update-launchpad-modal").find(".text-danger").show();
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.updatetemplate !== undefined
            && nextProps.auth.updatetemplate.data !== undefined
            && nextProps.auth.updatetemplate.data.retMsg !== undefined
          ) {
            $('#update-launchpad-modal').modal('hide');
            toast(nextProps.auth.updatetemplate.data.retMsg, {
                position: toast.POSITION.TOP_CENTER
            });
            nextProps.auth.updatetemplate = "";
        }
    }

    onChange = e => {
         $("#update-launchpad-modal").find(".text-danger").show();
        if (e.target.id === 'template-update-tokenname') {
            this.setState({ tokenname: e.target.value });
        }
         if (e.target.id === 'template-update-symbol') {
            this.setState({ symbol: e.target.value });
        }
         if (e.target.id === 'template-update-availablecurrency') {
            this.setState({ availablecurrency: e.target.value });
        }
          if (e.target.id === 'template-update-price') {
            this.setState({ price: e.target.value });
        }
          if (e.target.id === 'template-update-minAmt') {
            this.setState({ minAmt: e.target.value });
        }
          if (e.target.id === 'template-update-discount') {
            this.setState({ discount: e.target.value });
        }
         if (e.target.id === 'template-update-availablesale') {
            this.setState({ availablesale: e.target.value });
        }
         if (e.target.id === 'template-update-maxsupply') {
            this.setState({ maxsupply: e.target.value });
        }
         if (e.target.id === 'template-update-industry') {
            this.setState({ industry: e.target.value });
        }
        
         if (e.target.id === 'template-update-website') {
            this.setState({ website: e.target.value });
        }
          if (e.target.id === 'template-update-startdate') {
            this.setState({ startdate: e.target.value });
        }
        
        if (e.target.id === 'template-update-enddate') {
            this.setState({ enddate: e.target.value });
        }
    };

    onlaunchpadUpdate = e => {
        e.preventDefault();
         $("#update-launchpad-modal").find(".text-danger").show();
        const newTemplate = {
            _id: this.state.id,
            tokenname: this.state.tokenname,
            symbol   : this.state.symbol,
            availablecurrency: this.state.availablecurrency,
            price:this.state.price,
            minAmt:this.state.minAmt,
            discount:this.state.discount,
            availablesale:this.state.availablesale,
            maxsupply:this.state.maxsupply,
            industry:this.state.industry,
            website:this.state.website,
            subject: this.state.subject,
            content: this.state.content
        };
        
        this.props.updateLaunchpad(newTemplate);
    };

    render() {
        const { errors } = this.state;
        console.log("this@@@@@@",this.state.tokenname)

        return (
            <div>
                <div className="modal fade" id="update-launchpad-modal">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Launchpad</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                           <div className="modal-body">
                                <form noValidate onSubmit={this.onlaunchpadUpdate} id="update-launchpad" >
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="tokenname">Token Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.tokenname}
                                                id="template-update-tokenname"
                                                type="text"
                                                error={errors.tokenname}
                                                className={classnames("form-control", {
                                                    invalid: errors.tokenname
                                                })}/>
                                            <span className="text-danger">{errors.tokenname}</span>

                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="symbol">Token Symbol</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.symbol}
                                                error={errors.symbol}
                                                id="template-update-symbol"
                                                type="symbol"
                                                className={classnames("form-control", {
                                                    invalid: errors.symbol
                                                })}
                                            />
                                            <span className="text-danger">{errors.symbol}</span>
                                        </div>
                                    </div>
                                 <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="symbol">Available Currency</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.availablecurrency}
                                                error={errors.availablecurrency}
                                                id="template-update-availablecurrency"
                                                type="availablecurrency"
                                                className={classnames("form-control", {
                                                    invalid: errors.availablecurrency
                                                })}
                                            />
                                            <span className="text-danger">{errors.availablecurrency}</span>
                                        </div>
                                    </div>
                                        <div className="row mt-2">
                                  <div className="col-md-3">
                                  <label htmlFor="minimum">Token ICON</label>
                                  </div>
                                  <div className="col-md-9">
                                  <label class="custom-file-upload">
                                  <input
                                        type="file"
                                        accept="image/x-png,image/gif,image/jpeg"
                                        onChange={this.handleChangeefile}
                                        name="file1"
                                        id="exampleInputFile"
                                        aria-describedby="fileHelp"
                                      />
                                      Choose File
                                      </label>
                                      <span className="text-danger">{this.state.imageerr}</span>
                                     <p>{this.state.curimage}</p>
                                  </div>
                                </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="price">Token Launch Price</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.price}
                                                error={errors.price}
                                                id="template-update-price"
                                                type="price"
                                                className={classnames("form-control", {
                                                    invalid: errors.price
                                                })}
                                            />
                                            <span className="text-danger">{errors.price}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Minimum Purchase Amount</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.minAmt}
                                                id="template-update-minAmt"
                                                type="minAmt"
                                                className={classnames("form-control", {
                                                    invalid: errors.minAmt
                                                })}
                                            />
                                            <span className="text-danger">{errors.minAmt}</span>
                                        </div>
                                    </div>
                                      <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Discount</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.discount}
                                                id="template-update-discount"
                                                type="discount"
                                                className={classnames("form-control", {
                                                    invalid: errors.discount
                                                })}
                                            />
                                            <span className="text-danger">{errors.discount}</span>
                                        </div>
                                    </div>
                                       <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Token Available to Sale</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.availablesale}
                                                id="template-update-availablesale"
                                                type="availablesale"
                                                className={classnames("form-control", {
                                                    invalid: errors.availablesale
                                                })}
                                            />
                                            <span className="text-danger">{errors.availablesale}</span>
                                        </div>
                                    </div>
                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Token Max Supply</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.maxsupply}
                                                id="template-update-maxsupply"
                                                type="maxsupply"
                                                className={classnames("form-control", {
                                                    invalid: errors.maxsupply
                                                })}
                                            />
                                            <span className="text-danger">{errors.maxsupply}</span>
                                        </div>
                                    </div>
                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Industry</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.industry}
                                                id="template-update-industry"
                                                type="industry"
                                                className={classnames("form-control", {
                                                    invalid: errors.industry
                                                })}
                                            />
                                            <span className="text-danger">{errors.industry}</span>
                                        </div>
                                    </div>
                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Website</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.website}
                                                id="template-update-website"
                                                type="website"
                                                className={classnames("form-control", {
                                                    invalid: errors.website
                                                })}
                                            />
                                            <span className="text-danger">{errors.website}</span>
                                        </div>
                                    </div>
                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Start Date</label>
                                        </div>
                                        <div className="col-md-9">
                                         <DatePicker
                                          selected={ this.state.startDate }
                                          onChange={ this.handleChange }
                                          dateFormat="MMMM d, yyyy"
                                          className={classnames("form-control", {
                                                    invalid: errors.startDate
                                                })}
                                          />
                                            <span className="text-danger">{errors.startdate}</span>
                                        </div>
                                    </div>
                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">End Date</label>
                                        </div>
                                        <div className="col-md-9">
                                         <DatePicker
                                          selected={ this.state.endDate }
                                          onChange={ this.handleChange1 }
                                          dateFormat="MMMM d, yyyy"
                                          className={classnames("form-control", {
                                                    invalid: errors.endDate
                                                })}
                                          />
                                            <span className="text-danger">{errors.endDate}</span>
                                        </div>
                                    </div>
                                       <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="content">Content</label>
                                        </div>
                                        <div className="col-md-9">
                                        <Editor apiKey='5vk89nvvi2zckrb2lp2ctyyolewhq1v3pzdiwb7at68h40a5'
                                           initialValue="<p>This is the initial content of the editor</p>"
                                           value={this.state.content} onEditorChange={this.handleEditorChange}
                                           init={{
                                             height: 500,
                                             menubar: false,
                                             plugins: [
                                               'advlist autolink lists link image charmap print preview anchor',
                                               'searchreplace visualblocks code fullscreen',
                                               'insertdatetime media table paste code help wordcount'
                                             ],
                                             toolbar:
                                               'undo redo code | formatselect | bold italic backcolor | \
                                               alignleft aligncenter alignright alignjustify | \
                                               bullist numlist outdent indent | removeformat | help'
                                           }}
                                         />
                                            <span className="text-danger">{errors.content}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="update-launchpad"
                                    type="submit"
                                    className="btn btn-primary">
                                    Update Launchpad
                                </button>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>

        )
    }
}

LaunchpadUpdateModal.propTypes = {
    updateLaunchpad: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateLaunchpad }
)(withRouter(LaunchpadUpdateModal));
