import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import UserAddModal from "../partials/UserAddModal";
import KycViewModal from "../partials/UserUpdateModal";
import { toast, ToastContainer } from "react-toastify";
import keys from "../../actions/config";
import $ from 'jquery';




const url = keys.baseUrl;
class Users extends Component {

    constructor(props) {
        super(props);


        this.columns = [
            // {
            //     key: "name",
            //     text: "Name",
            //     className: "name",
            //     align: "left",
            //     sortable: true,
            // },
            {
                key: "email",
                text: "Email",
                className: "email",
                align: "left",
                sortable: true
            },
            {
                key: "Synapsverified",
                text: "SYNAPS Status",
                className: "action",
                align: "left",
                sortable: true
            },
            {
                key: "verifiedstatus",
                text: "KYC Status",
                className: "action",
                align: "left",
                sortable: true
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    if (record.verifiedstatus.toLowerCase() == "pending" || record.verifiedstatus.toLowerCase() == 'rejected') {//CANCELLED
                        return (
                            <Fragment>
                                <button
                                    data-toggle="modal"
                                    data-target="#kyc-view-modal"
                                    className="btn btn-primary btn-sm mr-2"
                                    onClick={() => { this.addRecord(record) }}
                                >
                                    <i className="fa fa-edit"></i>
                                </button>
                                {/* <button
                                    data-toggle="modal"
                                    data-target="#update-user-modal"
                                    className="btn btn-primary btn-sm mr-2"
                                    onClick={() => this.editRecord(record)}
                                > */}
                                {/* <i className="fa fa-check"></i> */}
                                {/* <i className="fa fa-edit"></i> */}
                                {/* </button> */}

                                {/* <button
                                   className="btn btn-primary btn-sm"
                                    onClick={() => this.changestatus(record)}>
                                    <i className={lockVal}></i>
                                </button> */}

                                {/* <button
                                    className="btn btn-danger btn-sm mr-2"
                                    onClick={() => this.deleteRecord(record)}>
                                    <i className="fa fa-trash"></i>
                                </button> */}
                            </Fragment>
                        );
                    } else if (record.verifiedstatus.toLowerCase() == "finished") {//FINISHED
                        // <i className=></i>
                        return (
                            <Fragment>

                                <button
                                    // data-toggle="modal"
                                    // data-target="#update-user-modal"
                                    className="btn btn-primary btn-sm mr-2"
                                >
                                    <i className="fa fa-lock"></i>
                                </button>
                                {/* <i className="fa fa-lock"></i> */}
                            </Fragment>
                        )

                    }
                    // return (
                    //     <Fragment>
                    //         <button
                    //             // data-toggle="modal"
                    //             // data-target="#update-user-modal"
                    //             className="btn btn-primary btn-sm mr-2"
                    //             onClick={() => this.editRecord(record)}
                    //             >
                    //             <i className="fa fa-edit"></i>
                    //         </button>

                    //          {/* <button
                    //            className="btn btn-primary btn-sm"
                    //             onClick={() => this.changestatus(record)}>
                    //             <i className={lockVal}></i>
                    //         </button> */}

                    //         {/* <button
                    //             className="btn btn-danger btn-sm mr-2"
                    //             onClick={() => this.deleteRecord(record)}>
                    //             <i className="fa fa-trash"></i>
                    //         </button> */}
                    //     </Fragment>
                    // );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Users",
            no_data_text: 'No user found!',
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            totalCount: 0,
            currentRecord: {
                records: [],
                id: '',
                name: '',
                email: '',
                active: '',
                status: '',
                password: '',
                password2: '',
                lockVal: '',
            },
            userRecord: {},
            kycModel: false,
            editKyc: false
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
        // console.log(this.state,'statezzzzzz');
    };

    // componentWillReceiveProps(nextProps) {
    //     this.getData()
    // }

    getData() {
        const data = {
            limit :10,
            page : 1,
            type: 'kyc'
          }
        axios
            .post(url + "api/user-data",data)
            .then(res => {
                const userData = res.data.user
                console.log("userData_final", userData);
                this.setState({ records: userData, totalCount: res.data.count })
                console.log(res, 'reszzzzhhhhhh');
            })
            .catch()
    }


    onAcceptID = async (id, email) => {
        const idaccept = {
            _id: id,
            email: email,
        };

        axios
            .post(url + "api/IDverifiedaccept", idaccept)
            .then((res) => {
                if (res.status === 200) {
                    this.setState({ IDstatus: res.data.updateddata.IDstatus });
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    this.getData();
                }
            })
            .catch();
    };
    // onAcceptAddress = async(id,email) => {

    //    const addressaccept = {
    //      _id: id,
    //      email: email,
    //    };
    //    axios
    //      .post(url + "api/addressaccept", addressaccept)
    //      .then((res) => {
    //        if (res.status === 200) {
    //          this.setState({ Addresstatus: res.data.updateddata.Addresstatus });

    //          toast(res.data.message, {
    //            position: toast.POSITION.TOP_CENTER,
    //          });
    //          this.getData();
    //        }
    //      })
    //      .catch();
    //  };
    // onAcceptPhoto = async(id,email) => {

    //     const photoaccept = {
    //       _id: id,
    //       email: email,
    //     };
    //     axios
    //       .post(url + "api/photoaccept", photoaccept)
    //       .then((res) => {
    //         if (res.status === 200) {
    //           this.setState({ Photostatus: res.data.updateddata.Photostatus });

    //           toast(res.data.message, {
    //             position: toast.POSITION.TOP_CENTER,
    //           });
    //           this.getData();
    //         }
    //       })
    //       .catch();
    //  };
    async editRecord(record) {
        // $("#update-user-modal").find(".text-danger").hide();

        // this.setState({ currentRecord: record});
        //this.props.history.push("/kycupdate/" + record._id);
        await this.onAcceptID(record._id, record.email)
        //    await this.onAcceptAddress(record._id,record.email)
        //    await this.onAcceptPhoto(record._id,record.email)
        // console.log("recode edit",this.state.currentRecord)

    }
    addRecord(record) {
        // if(this.state.kycModel===true){
        this.setState({ userRecord: record, errors: {} });
        console.log("addrecord", record);
        $("#kyc-view-modal").find(".text-danger").hide();
        $("#kyc-view-modal").find(".text-danger").hide();
        //    }
    }

    deleteRecord(record) {
        axios
            .post(url + "api/user-delete", { _id: record._id })
            .then(res => {
                if (res.status === 200) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
            .catch();
        this.getData();
    }

    changestatus(record) {
        axios
            .post(url + "api/user-changestatus", { _id: record._id })
            .then(res => {
                // console.log(res,'reschange');
                if (res.status === 200) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
            .catch();
        this.getData();
    }
    async pageChange(pageData) {
        console.log("OnPageChange_OnPageChange", pageData);
            const data = {
                limit: pageData.page_size,
                page: pageData.page_number,
                type: 'kyc',
                search: pageData.filter_value
            }
            const getData = await axios.post(url + "api/user-data", data);
            this.setState({ records: getData.data.user, totalCount: getData.data.count });
    
        }

    render() {
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <UserAddModal/>
                    
                       {console.log(this.state.userRecord,"userrecord")}
                    <KycViewModal record={this.state.userRecord && this.state.userRecord} getData ={this.getData}/>
                    
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            {/* <button className="btn btn-outline-primary float-right mt-0 mr-2 btn_pos_right" data-toggle="modal" data-target="#add-user-modal"><FontAwesomeIcon icon={faPlus}/> add</button> */}
                            <h3 className="mt-2 text-secondary">KYC Verification</h3>
                            <div className="resonsive_table">
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                    responsive={true}
                                    dynamic={true}
                                    total_record={this.state.totalCount}
                                    onChange={this.pageChange.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        );
    }

}

Users.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(Users);
