import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateFaq } from "../../actions/faqActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import {CKEditor} from 'ckeditor4-react'
import 'react-toastify/dist/ReactToastify.css';
class FaqUpdateModal extends React.Component {
    constructor(props) {
        super(props);
       $("#update-faq-modal").find(".text-danger").hide();
        this.state = {
            id: this.props.record._id,
            answer: this.props.record.answer,
            question: this.props.record.question,
            errors: {},
        };
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.record) {
            this.setState({
                id: nextProps.record._id,
                answer: nextProps.record.answer,
                question: nextProps.record.question
            })
        }
        if (nextProps.errors) {
           
            this.setState({
                errors: nextProps.errors
            });
        }
        
        if (nextProps.auth !== undefined
            && nextProps.auth.updatefaq !== undefined
            && nextProps.auth.updatefaq.data !== undefined
            && nextProps.auth.updatefaq.data.message !== undefined
            && nextProps.auth.updatefaq.data.success) {
            $('#update-faq-modal').modal('hide');
            toast(nextProps.auth.updatefaq.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            nextProps.auth.updatefaq = "";
        }
    }

    onChange = e => {
        $("#update-faq-modal").find(".text-danger").show();
        if (e.target.id === 'faq-update-question') {
            this.setState({ question: e.target.value });
        }
        if (e.target.id === 'faq-update-answer') {
            this.setState({ answer: e.target.value });
        }
    };

    onFaqUpdate = e => {
        e.preventDefault();
        $("#update-faq-modal").find(".text-danger").show();
        const newFaq = {
            _id: this.state.id,
            answer: this.state.answer,
            question: this.state.question
            
        };
        this.props.updateFaq(newFaq);
    };

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-faq-modal">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Faq</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onFaqUpdate} id="update-faq">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.id}
                                        id="faq-update-id"
                                        type="text"
                                        className="d-none"/>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Subject</label>
                                        </div>
                                        <div className="col-md-9">
                                        <textarea
                                            onChange={this.onChange}
                                            value={this.state.question}
                                            id="faq-update-question"
                                            type="text"
                                            error={errors.question}
                                            className={classnames("form-control", {
                                                invalid: errors.question
                                            })}/>
                                            <span className="text-danger">{errors.name}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="answer">Identifier</label>
                                    </div>
                                    <div className="col-md-9">
                                        {/* <textarea
                                            onChange={this.onChange}
                                            value={this.state.answer}
                                            error={errors.answer}
                                            id="faq-update-answer"
                                            type="text"
                                            className={classnames("form-control", {
                                                invalid: errors.answer
                                            })}
                                        /> */}
                                        <CKEditor
                                                config={{
                                                    //extraAllowedContent: 'div(*)',
                                                    allowedContent: true,
                                                    height: 500,
                                                }}
                                                initData={this.state.answer}
                                                onChange={(e) => { this.setState({ answer: e.editor.getData() }) }}
                                            /> 
                                            <span className="text-danger">{errors.answer}</span>
                                        </div>
                                    </div>
                                    

                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="update-faq"
                                    type="submit"
                                    className="btn btn-primary">
                                    Update Faq
                                </button>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>

        )
    }
}

FaqUpdateModal.propTypes = {
    updateFaq: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateFaq }
)(withRouter(FaqUpdateModal));
