import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { replyContact } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import { Editor } from '@tinymce/tinymce-react';
import {CKEditor} from 'ckeditor4-react'
import 'react-toastify/dist/ReactToastify.css';
class ContactUpdateModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // id: this.props.record._id,
            // name: this.props.record.name,
            // email: this.props.record.email,
            // mobilenumber: this.props.record.mobilenumber,
            // message: this.props.record.message,
            // reply: this.props.record.reply,
            id: "",
            name: "",
            email: "",
            mobilenumber: "",
            message: "",
            reply: "",
            errors: {},
        };
        this.handleEditorChange = this.handleEditorChange.bind(this);
    }

    handleEditorChange(e) {
        console.log("e",e)
       this.setState({ "reply":e.target.value});
     }
    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {
            this.setState({
                id: nextProps.record._id,
                name: nextProps.record.name,
                email: nextProps.record.email,
                mobilenumber: nextProps.record.mobilenumber,
                message: nextProps.record.message,
                reply: nextProps.record.reply ? nextProps.record.reply : "" ,
            })
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        console.log(nextProps);

 

        console.log(nextProps)
        if (nextProps.auth !== undefined
            && nextProps.auth.replycontact !== undefined
            && nextProps.auth.replycontact.data !== undefined
            && nextProps.auth.replycontact.data.message !== undefined
            && nextProps.auth.replycontact.data.success) {
            $('#update-cms-modal').modal('hide');
            toast(nextProps.auth.replycontact.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            nextProps.auth.replycontact = "";
        }
    }
   onContactReplay = e => {
        e.preventDefault();
        const newReply = {
            _id: this.state.id,
            reply: this.state.reply
        };
        this.props.replyContact(newReply);
        $("#update-contact-modal").modal("hide");
        this.onHide()
    };
    onHide=()=>{
        this.setState({"reply":""})
    }

    render() {
        const { errors ,reply} = this.state;
        console.log(this.state,'contactus state');
        return (
            <div>
                <div className="modal fade" id="update-contact-modal">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Reply to contact</h4>
                                <button type="button" className="close" data-dismiss="modal" onClick={this.onHide}>&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onContactReplay} id="update-contact">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.id}
                                        id="contact-update-id"
                                        type="text"
                                        className="d-none"/>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Name</label>
                                        </div>
                                        <div className="col-md-9">
                                        {this.state.name}
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Email</label>
                                        </div>
                                        <div className="col-md-9">
                                        {this.state.email}
                                        </div>
                                    </div>
                                    
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Message</label>
                                        </div>
                                        <div className="col-md-9">
                                        {this.state.message}
                                        </div>
                                    </div>
                                    {/* <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="reply">Reply</label>
                                        </div>
                                        <div className="col-md-9">
                                        {console.log(reply == "")}
                                        {reply && reply == "" ? 
                                        (<CKEditor
                                         config={{
                                            extraAllowedContent: 'div(*)',
                                            allowedContent: true,
                                            height: 500,
                                          }}
                                          initData={reply}
                                          onChange={this.handleEditorChange}
                                         />) :
                                         (<CKEditor
                                         config={{
                                            extraAllowedContent: 'div(*)',
                                            allowedContent: true,
                                            height: 500,
                                          }}
                                          initData={reply}
                                          onChange={this.handleEditorChange}
                                         />)}
                                            <span className="text-danger">{errors.reply}</span>
                                        </div>
                                    </div> */}
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="reply">Reply</label>
                                        </div>
                                        <div className="col-md-9">
                                            <textarea className='form-control'
                                            value={reply}
                                                onChange={this.handleEditorChange}
                                            >

                                            </textarea>
                                            <span className="text-danger">{errors.reply}</span>
                                        </div>
                                    </div>

                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.onHide}>Close</button>
                                <button
                                    form="update-contact"
                                    type="submit"
                                    className="btn btn-primary">
                                    Reply
                                </button>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>

        )
    }
}

ContactUpdateModal.propTypes = {
    replyContact: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { replyContact }
)(withRouter(ContactUpdateModal));
