import axios from "axios";
import {
    GET_ERRORS,
    CURRENCY_ADD,
    CURRENCY_UPDATE,
} from "./types";
import keys from "./config";
const url = keys.baseUrl;
export const addcurrency = (currencyData) => dispatch => {
    axios
        .post(url+"api/currency_add", currencyData)
        .then(res =>
            dispatch({
                type: CURRENCY_ADD,
                payload: res,
            })
        ).catch(err => 
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};


export const currencyupdate = (faqData) => dispatch => {
    axios
        .post(url+"api/currency_update", faqData)
        .then(res =>
            dispatch({
                type: CURRENCY_UPDATE,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};
