// import package
import React from 'react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';

import keys from "../../actions/config";
import axios from "axios";

const url = keys.baseUrl;


const ConfirmationModal = (props) => {
  console.log("ConfirmationModal",props);
    // props
   const [DeleteUser, setDelete] = useState(true);

//    const { t, i18n } = useTranslation();

    // function
    const handleCancelOrder = async (e) => {
        e.preventDefault();
        const {record}=props
        axios
            .post(url+"api/user-delete", {_id: record._id})
            .then(res => {
                if (res.status === 200) {
                //    toast(res.data.message, {
                //        position: toast.POSITION.TOP_CENTER,
                //    })
                   props.getData()
                   props.onDismiss()
                }
            })
            .catch();
            // props.getData();
            props.onDismiss()
    };
    return (
        <Modal
            show={DeleteUser}
            backdrop="static"
            size="md"
            centered
        >
      <Modal.Header>
        
        <Modal.Title>
          <h4 className="modal-title mt-0">
           Delete User
          </h4>
        </Modal.Title>
        <button type="button" class="close" onClick={()=>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

      </Modal.Header>
      <Modal.Body>
        <div className="modedl_subscribe_content">
        
        <b>{props.record.name ? props.record.name :  props.record.email.split('@')[0].substring(0, 10).toUpperCase()}</b>
        <p className='text-white mt-3 f-14'>
          Are you sure you want to delete the user?</p>
        
          <div className="d-flex justify-content-between mt-4 pb-4">
          
          <button type="button" class="btn btn-secondary w-100 mt-3 mr-3"  onClick={()=>props.onDismiss()}>Cancel</button>
            <button
              type="button"
              onClick={handleCancelOrder}
              class="btn btn-primary w-100 mt-3"             
            >
             Confirm
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    )
}

export default ConfirmationModal;


