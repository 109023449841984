import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import { Modal, Button } from 'react-bootstrap/';
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import $ from 'jquery';
import keys from "../../actions/config";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {faUserAlt} from "@fortawesome/free-solid-svg-icons/faUserAlt";
import { WithdrawExportcsv } from "../../actions/userActions";
const url = keys.baseUrl;

class Withdraw extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "receiveraddress",
                text: "To Address",
                className: "receiveraddress",
                align: "left",
                sortable: true,
                width: 200,
                cell: record => {
                    return(
                        record.receiveraddress == "" ||  record.receiveraddress == null ||  record.receiveraddress == undefined ?
                        record.receiverCryptoAddress : record.receiveraddress
                    )
                }

            },
            {
                key: "cryptoType",
                text: "Coin",
                className: "cryptoType",
                align: "left",
                sortable: true
            },
            {
                key: "username",
                text: "User Name",
                className: "userId",
                align: "left",
                sortable: true,
                cell: record => {
                    return(
                        record.username == "" ||  record.username == null ||  record.username == undefined ?
                        record.cryptoType : record.username
                    )
                }

            },
            {
                key: "transferamount",
                text: "Transfer Amount",
                className: "transferamount",
                align: "left",
                sortable: true
            },
            {
                key: "status",
                text: "Status",
                className: "status",
                align: "left",
                sortable: true
            },
            {
                key: "created_date",
                text: "Created Date",
                className: "created_date",
                align: "left",
                sortable: true
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    console.log(record, 'ddddddddd')
                    if (record.status == "Confirmed") {
                        return (
                            record.type == 'fiat' ? "Completed" :
                            <Fragment>
                                <button
                                    data-toggle="modal"
                                    data-target="#show-withdraw-modal"
                                    className="fa fa-stack-exchange btn-primary mr-2"
                                    onClick={() => this.showtransactionid(record)}
                                >
                                </button>
                            </Fragment>
                        );
                    }
                    if (record.status == "Pending") {

                        return (
                            record.type == 'fiat'? 
                            
                            <Fragment>
                                <button
                                    data-toggle="modal"
                                    data-target="#show-withdraw-modal"
                                    className="btn btn-primary btn-sm mr-2"
                                    onClick={() => this.showFiatModel(record)}
                                >
                                    <i className="fa fa-check"></i>
                                </button>
                            </Fragment>
                            :

                            <Fragment>
                            <button
                                data-toggle="modal"
                                data-target="#show-withdraw-modal"
                                className="btn btn-primary btn-sm mr-2"
                                onClick={() => this.showDetails(record)}
                            >
                                <i className="fa fa-check"></i>
                            </button>
                        </Fragment>
                        );
                    }
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Withdraw",
            no_data_text: 'No Withdraw found!',
            language: {
                length_menu: "Show _MENU_ results per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            showDetails: false,
            showDetails1: false,
            id: '',
            status: '',
            transferamount: '',
            finalamount: '',
            userId: '',
            cryptoType: '',
            userId: '',
            receiveraddress: '',
            tagid: '',
            errors: '',
            transactionId: '',
            startDate: "",
            endDate: "",
            totalCount: 0,
            fiatDetails: {},
            fiat: false

        };
        this.getData = this.getData.bind(this);
        this.downloadCSV = this.downloadCSV.bind(this)
    }

    componentDidMount() {
        this.getData()
    };

    showFiatModel(record){
        this.setState({ fiatDetails:record })
        this.setState({fiat:true})
    }
    showtransactionid = (record) => {
        this.setState({ transactionId: record.transactionId })
        this.setState({ showDetails1: true })
    }
    fiatHide(){
        this.setState({fiat: false})
    }
    showDetails = (record) => {
        console.log(record, 'record')
        this.setState({ receiveraddress: record.receiveraddress })
        this.setState({ tagid: '' })
        this.setState({ finalamount: record.finalamount })
        this.setState({ id: record._id })
        this.setState({ userId: record.userId })
        this.setState({ cryptoType: record.cryptoType })
        this.setState({ transferamount: record.transferamount })
        this.setState({ status: record.status })
        this.setState({ showDetails: true })
    }
    confirmSubmit = () => {
        var id = this.state.id;
        var passVal = { id: id, status: "Confirmed" };
        axios
            .post(url + "api/updatewithdraw", passVal)
            .then(res => {
                toast(res.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                this.setState({ showDetails: false });
                this.getData();
            })
            .catch()
    }
    fiatConfirmSubmit = (record) => {
        var id = this.state.fiatDetails._id;
        var passVal = { id: id, status: record };
        axios
        .post(url + "api/updatewithdrawFiat", passVal)
        .then(res => {
            toast(res.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            this.setState({ fiat: false });
            this.getData();
        })
        .catch()
    }
    rejectSubmit = () => {
        var id = this.state.id;
        var amount = this.state.finalamount;
        var userid = this.state.userId;
        var cryptoType = this.state.cryptoType;
        var passVal = { id: id, status: "Rejected", amount: amount, userid: userid, cryptoType: cryptoType };
        axios
            .post(url + "api/updatewithdraw", passVal)
            .then(res => {
                toast(res.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                this.setState({ showDetails: false });
                this.getData();
            })
            .catch()
    }

    handleClosedetails = (record) => {
        this.setState({ showDetails: false })
    }

    handleClosedetails1 = (record) => {
        this.setState({ showDetails1: false })
    }

    getData() {
        const data = {
            limit :10,
            page : 1
          }
        axios
            .post(url + "api/withdraw-data", data)
            .then(res => {
                console.log("withsrawwww",res);
                this.setState({ records: res.data.data, totalCount: res.data.count })
                this.setState({ userId: res.data.userId })
            })
            .catch()
    }


    async pageChange(pageData) {
        const data = {
            limit: pageData.page_size,
            page: pageData.page_number,
            search: pageData.filter_value
        }
        const getData = await axios.post(url + "api/withdraw-data", data);
        this.setState({ records: getData.data.data, totalCount: getData.data.count });

        console.log("OnPageChange_OnPageChange", pageData);
    }
    // async handleChange(type, date) {
    //     console.log("TYPESSSSSSSSSs" , this.state.startDate , this.state.endDate)
    //     if (this.state.startDate == "" && this.state.endDate != "" && date != "") {
    //         console.log("TYPESSSSSSSSSs", "both", this.state.startDate , date);
    //         const filterData = await axios.post(url + "api/withdraw-data_datefilter", { 
    //             date: {
    //             from: new Date(date).getTime() , 
    //             to: this.state.endDate
    //         }, type: "both" 
    //         });
    //         console.log("filterData", filterData)
    //     } 
    //     else if (this.state.endDate == "" && this.state.startDate != "" && date != "") {
    //         console.log("TYPESSSSSSSSSs", "both", this.state.endDate , date);
    //         const filterData = await axios.post(url + "api/withdraw-data_datefilter", { 
    //             date: {  
    //             from: this.state.startDate, 
    //             to: new Date(date).getTime()
    //         }, type: "both" 
    //         });
    //         console.log("filterData", filterData)
    //     }else if (type == "endDate" && date != "" ) {
    //         console.log("TYPESSSSSSSSSs", "to", date);
    //         const filterData = await axios.post(url + "api/withdraw-data_datefilter", { date: {to: new Date(date).getTime()}, type: "to" });
    //         console.log("filterData", filterData)
    //     } else if (type == "startDate" && date != "") {
    //         console.log("TYPESSSSSSSSSs", "from",date);
    //         const filterData = await axios.post(url + "api/withdraw-data_datefilter", { date: {from: new Date(date).getTime()}, type: "from" });
    //         console.log("filterData", filterData)
    //     }
    // }

    async handleChange(type, date) {

        const data = new FormData();
        data.append('to', new Date(date));
        data.append('from', new Date(this.state.startDate));
        data.append('type', "both");
        const filterData = await axios.post(url + "api/withdraw-data_datefilter", data);
        console.log("filterData_filterData",filterData);
    }

    async sortByDate() {
        if (this.state.startDate != "" || this.state.endDate != "") {
            const formData = new FormData();
            formData.append("from", this.state.startDate);
            formData.append("to", this.state.endDate);
            formData.append("type", "search");
            const data = await axios.post(url + "api/withdraw-data_datefilter",formData);
            console.log("data_data",data.data);
            this.setState({ records: data.data })
        } else {
            console.log("Select date first");
        }
    }

    async downloadCSV() {
        let data = {
            export: 'csv',
            from: this.state.startDate,
            to: this.state.endDate,
        }
        const download = await WithdrawExportcsv(data);
        console.log("downloadCSV_downloadCSV", download);
    }

    // dates.end=tomorrowdate -(new Date().getHours()*60*60+(new Date().getMinutes())*60+(new Date().getSeconds()))*1000
    render() {
        console.log(this.state.startDate, this.state.endDate,'res.data.userIdres.data.userId')
        return (
            <div>
                <Modal show={this.state.showDetails} onHide={this.handleClosedetails} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="popUpSpace">
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="answer">To address</label>
                                </div>
                                <div className="col-md-9">
                                    {this.state.receiveraddress}
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="answer">Tag id/Memo</label>
                                </div>
                                <div className="col-md-9">
                                    {this.state.tagid}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="answer">Transfer Coin</label>
                                </div>
                                <div className="col-md-9">
                                    {this.state.cryptoType}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="answer">Transfer Amount</label>
                                </div>
                                <div className="col-md-9">
                                    {this.state.transferamount}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="answer">Status</label>
                                </div>
                                <div className="col-md-9">
                                    {this.state.status}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger btnDefaultNewBlue" onClick={this.rejectSubmit}>
                            Reject
                        </Button>
                        <Button onClick={this.confirmSubmit} variant="success btnDefaultNew" >
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showDetails1} onHide={this.handleClosedetails1} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Transaction Id</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="popUpSpace">
                            <div className="row mt-2">

                                <div className="col-md-12" style={{ "word-wrap": "break-word" }}>
                                    {this.state.transactionId ? this.state.transactionId : "There is no transactionId"}
                                </div>
                            </div>


                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.fiat} onHide={this.fiatHide} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="popUpSpace">
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="answer">To address</label>
                                </div>
                                <div className="col-md-9">
                                    {this.state.fiatDetails.receiverCryptoAddress}
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="answer">Bankname</label>
                                </div>
                                <div className="col-md-9">
                                    {this.state.fiatDetails.bankName}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="answer">Holder Name</label>
                                </div>
                                <div className="col-md-9">
                                    {this.state.fiatDetails.holderName}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="answer">IFSC</label>
                                </div>
                                <div className="col-md-9">
                                    {this.state.fiatDetails.ifsc}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="answer">Transfer Amount</label>
                                </div>
                                <div className="col-md-9">
                                    {this.state.fiatDetails.transferamount}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="answer">Status</label>
                                </div>
                                <div className="col-md-9">
                                    {this.state.fiatDetails.status}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger btnDefaultNewBlue" onClick={()=> {this.fiatConfirmSubmit('Rejected')}}>
                            Reject
                        </Button>
                        <Button onClick={()=> {this.fiatConfirmSubmit('Confirmed')}} variant="success btnDefaultNew" >
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h3 className="mt-2 text-secondary">Withdraw List</h3>
                            <div className="mt-4">
                                <button onClick={this.downloadCSV} className='btn btn-rec mr-2 mb-2' style={{ width: '118px', height: '35px', fontSize: '13px' }}>Download(CSV)</button>
                                {/* <button onClick={this.exportPDF} className='btn btn-rec mr-2 mb-2' style={{ width: '118px', height: '35px', fontSize: '13px' }}>Download(CSV)</button>
                            <button onClick={this.exportPDF} className='btn btn-rec mr-2 mb-2' style={{ width: '118px', height: '35px', fontSize: '13px' }}>Download(XLS)</button> */}

                            </div>
                            <div>
                                <p className="filter_date_text">Filter based on date</p>
                                <div className="row">
                                    <div className="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 mt-2">
                                        <label>From Date</label>
                                        <DatePicker
                                            selected={this.state.startDate}
                                            maxDate={new Date()}
                                            onChange={(date) => { this.setState({ startDate: date}) }}
                                            dateFormat="MMMM d, yyyy"
                                            className={classnames("form-control", {
                                            })}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 mt-2">
                                        <label>To Date</label>
                                        <DatePicker
                                            selected={this.state.endDate}
                                            maxDate={new Date()}
                                            onChange={(date) => { this.setState({ endDate: date}) }}
                                            minDate={this.state.startDate}
                                            dateFormat="MMMM d, yyyy"
                                            className={classnames("form-control", {
                                            })}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 mt-2">
                                    <button className="btn btn-light mt_seact_date" onClick={()=> {this.sortByDate()}}>Search</button>
                                    </div>
                                </div>
                            </div>
                            <div className="resonsive_table">
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                    responsive={true}
                                    dynamic={true}
                                    total_record={this.state.totalCount}
                                    onChange={this.pageChange.bind(this)}
                                    className="table table-bordered table-striped withdraw_dt_table"
                                />
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        );
    }

}

Withdraw.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(Withdraw);
