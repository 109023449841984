import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { resetPassword } from "../../actions/authActions";
import classnames from "classnames";
import keys from "../../actions/config";
import { toast, ToastContainer } from "react-toastify";
import authLogo from "../../images/magnituteLogo.png"


class Login extends Component {
    constructor() {
        super();
        this.state = {
            newPassword: "",
            confirmPassword: "",
            errors: {}
        };
    }

    componentDidMount() {
        if (this.props.auth.isAuthenticated) {
            this.props.history.push("/dashboard");
        }
    };

    componentWillReceiveProps(nextProps) {

        if (nextProps.auth.isAuthenticated) {
            this.props.history.push("/dashboard");
        }

        if (nextProps.auth != undefined && nextProps.auth.Changepassword != undefined) {
            toast(nextProps.auth.Changepassword, {
                position: toast.POSITION.TOP_CENTER
            });

            setTimeout(() => {
                this.props.history.push('/login')
            }, 3000)
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        else {
            this.setState({
                errors: {}
            });
        }
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSubmit = e => {
        e.preventDefault();

        const userData = {
            newPassword: this.state.newPassword,
            confirmPassword: this.state.confirmPassword,
            userId: this.props.match.params.id
        };
        this.props.resetPassword(userData);
    };

    render() {
        const { errors } = this.state;
        return (
            <div className="container">
                 <div className="flex_center">
                <div className="row my-5">
                    <div className="col-md-12 mx-auto">
                    <div className="card">
                        <div className="card-body p-4">
                       <center className="pt-3">
                          {/* <h2 className="form_header">EXCHANGE ONE</h2> */}
                          <img src={authLogo} className="img-fluid img_form" alt="Logo" />
                           </center>
                            <h2 className="text-center text-primary mt-3">Reset Password</h2>
                            <form noValidate onSubmit={this.onSubmit} className="white formcur_default">

                                <label htmlFor="password">New  Password</label>
                                <input
                                    onChange={this.onChange}
                                    value={this.state.newPassword}
                                    error={errors.newPassword}
                                    id="newPassword"
                                    type="password"
                                    className={classnames("form-control", {
                                        invalid: errors.newPassword
                                    })}
                                />
                                <span className="text-danger">{errors.newPassword}</span>
                                <br />
                                <label htmlFor="password">confirm Password</label>
                                <input
                                    onChange={this.onChange}
                                    value={this.state.confirmPassword}
                                    error={errors.confirmPassword}
                                    id="confirmPassword"
                                    type="password"
                                    className={classnames("form-control", {
                                        invalid: errors.confirmPassword
                                    })}
                                />
                                <span className="text-danger">{errors.confirmPassword}</span>
                                <p className="text-center pb-0 mt-2">
                                    <button
                                        type="submit"
                                        className="btn btn-large btn-primary mt-2 px-5">
                                        ResetPassword   </button>
                                </p>
                                <p className="grey-text text-darken-1 font_15 mt-4">
                                    Go to Login? <Link to="/login" className="link_blue_new">Login</Link>
                                </p>


                            </form>
                            </div>
                            </div>
                        </div>

                    </div>
                </div>
                <ToastContainer />

            </div>
        );
    }
}

Login.propTypes = {
    resetPassword: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { resetPassword }
)(Login);
