import React, { Component, Fragment } from "react";
import ReactDatatable from '@ashvin27/react-datatable';

//import compents
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";

//import actions
import {perpetualTradeHistory} from '../../actions/reportActions'
import { withRouter } from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import axios from "axios";
import keys from "../../actions/config"
const url = keys.baseUrl;
class LoginHistory extends Component {
    constructor(props) {
        super(props);
        console.log("prpos",props)
        this.columns = [
           
            {
                key: "sno",
                text: "S.No",
                className: "Sno",
                align: "left",
                sortable: true,
                width: 200,
                cell:(records,i)=>{
                    return (
                        <Fragment>
                            {i+1}
                        </Fragment>
                    );
                }
            },           
                  
            {
                key: "ipaddress",
                text: "IP Address",
                className: "IPaddress",
                align: "left",
                sortable: true,
                width: 200,
            },
           
            {
                key: "os",
                text: "Operating System/Browser",
                className: "Browser",
                align: "left",
                sortable: true,
                width: 200,
            },
            {
                key: "region",
                text: "Country/Region",
                className: "Excuted",
                align: "left",
                sortable: true,
                width: 200,
                cell:records=>{
                    var country_name= records.countryName
                    var region = records.rregionName
                    return (
                        <Fragment>
                            { country_name+ "/" + region}
                        </Fragment>
                    );
                }
            },
           
            {
                key: "ismobile",
                text: "IsMobile?",
                className: "Ismobile",
                align: "left",
                sortable: true,
                width: 200,
            },
            {
                key: "status",
                text: "Status",
                className: "Status",
                align: "left",
                sortable: true,
                width: 200,
            },
            {
                key: "Date",
                text: "Date",
                className: "Date",
                align: "left",
                sortable: true,
                width: 200,
                cell:records=>{
                    var data1 = new Date(records.createdDate);
                    let date = data1.getFullYear() + '-' + (
                        data1.getMonth() + 1) + '-' + data1.getDate() + ' ' + data1.getHours() + ':' + data1.getMinutes() + ':' + data1.getSeconds();
                    date = (data1.getFullYear() != 1970)
                        ? date
                        : '';
                    return(
                        <Fragment>
                        {date}
                    </Fragment>
                    )
                }
               
            },
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Order",
            no_data_text: 'No Records found!',
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };
        this.state = {
            records: [],
        };

    }
    getData(){
        let id = this.props.auth.user.id;
        axios.get(url+"api/userget/"+id).then(res=>{
            console.log(res.data.loginhistory,"res")
            this.setState({records:res.data.loginhistory})
        })
    }
    componentDidMount() {
        this.getData()
    };
    render() {

        const { records,count } = this.state
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h3 className="mt-2 text-secondary">Login History</h3>
                            <div className="resonsive_table">
                            <ReactDatatable
                                config={this.config}
                                records={records}
                                columns={this.columns}
                                dynamic={false}
                                total_record={count}
                                // onChange={this.handlePagination} 
                                className="table table-bordered table-striped login_hist_table" />
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

LoginHistory.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
  };
  const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
  });
  export default connect(
    mapStateToProps,
  )(withRouter(LoginHistory));

