import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateCms } from "../../actions/cmsActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import { Editor } from '@tinymce/tinymce-react';
import {CKEditor} from "ckeditor4-react"
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import keys from "../../actions/config";
const url = keys.baseUrl;
class CmsUpdateModal extends React.Component {
    constructor(props) {
        super(props);
        console.log("props",props)
        this.state = {
            // id: this.props.record._id,
            // identifier: this.props.record.identifier,
            // subject: this.props.record.subject,
            // content: this.props.record.content,
            // image: this.props.record.image,
            id: "",
            identifier: "",
            subject: "",
            content: "",
            image: "",
            errors: {},
        };
       
        this.handleEditorChange = this.handleEditorChange.bind(this);
    }


    handleEditorChange(e) {
       this.setState({ "content":e.editor.getData() });
     }

    componentWillReceiveProps(nextProps) {
        console.log("nextProps",nextProps)
        if (nextProps.record) {
            console.log("nextProps.record",nextProps.record)
            this.setState({
                id: nextProps.record._id,
                identifier: nextProps.record.identifier,
                subject: nextProps.record.subject,
                content: nextProps.record.content,
                image: nextProps.record.image
            })
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        console.log(nextProps)
        if (nextProps.auth !== undefined
            && nextProps.auth.updatecms !== undefined
            && nextProps.auth.updatecms.data !== undefined
            && nextProps.auth.updatecms.data.message !== undefined
            && nextProps.auth.updatecms.data.success) {
            $('#update-cms-modal').modal('hide');
            toast(nextProps.auth.updatecms.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            nextProps.auth.updatecms = "";
        }
    }
 
    onChange = e => {
        if (e.target.id === 'cms-update-subject') {
            this.setState({ subject: e.target.value });
        }
        if (e.target.id === 'cms-update-identifier') {
            this.setState({ identifier: e.target.value });
        }
    };
     fileSelectedHandler = (event) => {
         console.log(event,'event');
    this.setState({ image: event.target.files })
  }


    onCmsUpdate = e => {
        e.preventDefault();
        const newCms = {
            _id: this.state.id,
            identifier: this.state.identifier,
            subject: this.state.subject,
            content: this.state.content,
            image: this.state.image
        };
        const data = new FormData();
        data.append('identifier', this.state.identifier);
        data.append('subject', this.state.subject);
        data.append('content', this.state.content);
        //data.append('file', []);
        data.append('_id', this.state.id);
        
         for (const key of Object.keys(this.state.image)) {
            data.append('file', this.state.image[key])
        }
        console.log("UpdateCmsRecords",{
            id:this.state.identifier,
            sub:this.state.subject,
            cont:this.state.content
        })
        this.props.updateCms(data);
        this.props.onHide()
        console.log(newCms,'newCms');
    };
    render() {
        const { errors,content } = this.state;
        const {onHide}=this.props
        return (
            <div>
                <div className="modal fade" id="update-cms-modal">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Cms</h4>
                                <button type="button" className="close" data-dismiss="modal" onClick={()=>{onHide()}}>&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onCmsUpdate} id="update-cms">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.id}
                                        id="cms-update-id"
                                        type="text"
                                        className="d-none"/>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Identifier</label>
                                        </div>
                                        <div className="col-md-9">
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.subject}
                                            id="cms-update-subject"
                                            type="text"
                                            error={errors.subject}
                                            className={classnames("form-control", {
                                                invalid: errors.subject
                                            })} readOnly/>
                                            <span className="text-danger">{errors.name}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="identifier">Page Name</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.identifier}
                                            error={errors.identifier}
                                            id="cms-update-identifier"
                                            type="text"
                                            className={classnames("form-control", {
                                                invalid: errors.identifier
                                            })}
                                        />
                                            <span className="text-danger">{errors.identifier}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="content">Content</label>
                                        </div>
                                        <div className="col-md-9">
                                        {/* <Editor apiKey='5vk89nvvi2zckrb2lp2ctyyolewhq1v3pzdiwb7at68h40a5'
                                           initialValue={this.state.content}
                                           value={this.state.content} onEditorChange={this.handleEditorChange}
                                           init={{
                                             height: 500,
                                             menubar: false,
                                             plugins: [
                                               'advlist autolink lists link image charmap print preview anchor',
                                               'searchreplace visualblocks code fullscreen',
                                               'insertdatetime media table paste code help wordcount'
                                             ],
                                             toolbar:
                                               'undo redo code | formatselect | bold italic backcolor | \
                                               alignleft aligncenter alignright alignjustify | \
                                               bullist numlist outdent indent | removeformat | help'
                                           }}
                                         /> */}
                                            {content && 
                                                <CKEditor
                                                    config={{
                                                        //extraAllowedContent: 'div(*)',
                                                        allowedContent: true,
                                                        height: 500,
                                                    }}
                                                    initData={content}
                                                    onChange={this.handleEditorChange}
                                                />
                                            }
                                            <span className="text-danger">{errors.content}</span>
                                        </div>
                                    </div>

                                   {/* <div className="row mt-2">
                                        <div className="col-md-3">
                                        <br/>
                                        <label htmlFor="image">Image</label>
                                        </div>
                                        <div className="col-md-9">
                                        <label class="custom-file-upload">
                                        <input type="file" multiple onChange={this.fileSelectedHandler}
                                        />
                                        Choose File
                                        </label>
                                      
                                        </div>
                                    </div> */}

                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={()=>{onHide()}}>Close</button>
                                <button
                                    form="update-cms"
                                    type="submit"
                                    className="btn btn-primary">
                                    Update Cms
                                </button>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>

        )
    }
}

CmsUpdateModal.propTypes = {
    updateCms: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateCms }
)(withRouter(CmsUpdateModal));
