import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updatepair } from "../../actions/perpetualActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import { Editor } from '@tinymce/tinymce-react';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import axios from "axios";
import keys from "../../actions/config";
const url = keys.baseUrl;
const options = [{ 'value': "On", 'label': "On" }, { 'value': "Off", 'label': "Off" }];

class PairUpdateModal extends React.Component {
    constructor(props) {
        super(props);
        $("#update-perpetual-modal").find(".text-danger").hide();
        console.log('PairUpdateModal_PairUpdateModal',this.props.record);
        // console.log(this.props.record,'fsdfnskdfsdjkf');
        // console.log(this.props.record.ticker_root,'fsdfnskdfsdjkf');
        this.state = {
            id: this.props.record._id,
            tickerroot: this.props.record.ticker_root,
            hidden: this.props.record.ticker_root,
            initial_margin: this.props.record.initial_margin,
            maint_margin: this.props.record.maint_margin,
            interest_base_symbol: this.props.record.interest_base_symbol,
            interest_quote_symbol: this.props.record.interest_quote_symbol,
            funding_premium_symbol: this.props.record.funding_premium_symbol,
            risk_limit: this.props.record.risk_limit,
            risk_step: this.props.record.risk_step,
            minpriceincrement: this.props.record.minpriceincrement,
            maxpriceincrement: this.props.record.maxpriceincrement,
            contract_size: this.props.record.contract_size,
            maxquantity: this.props.record.maxquantity,
            minquantity: this.props.record.minquantity,
            mark_price: this.props.record.mark_price,
            leverage: this.props.record.leverage,
            maker_rebate: this.props.record.maker_rebate,
            dailyinterest: this.props.record.dailyinterest,
            taker_fees: this.props.record.taker_fees,
            markuppercentage: this.props.record.markuppercentage,
            lotsize: this.props.record.lotsize,
            funding_interval: this.props.record.funding_interval,
            floatingDigits: this.props.record.floatingDigits,
            first_currency: { label: this.props.record.first_currency, value: this.props.record.first_currency },
            first_currency: { label: this.props.record.second_currency, value: this.props.record.second_currency },
            bot: { label: this.props.record.botstatus, value: this.props.record.botstatus },
            maxPrice: this.props.record.maxPrice,
            minPrice: this.props.record.minPrice,
            errors: {},
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChange1 = this.handleChange1.bind(this);
    }

    styles = {
        option: (styles, {isFocused, isSelected}) => ({
            ...styles,
            color: "white",
            background: isFocused
                ? '#1d296a'
                : isSelected
                    ? '#113853'
                    : "#81c8f6",
            zIndex: 1
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          height: '52px',
          padding: '0 6px',
          backgroundColor: "#03081f",
          borderColor: '#81c8f6',
        borderRadius: 10,
        borderStyle: 'solid',
        borderWidth: '1px'
         
        }),
        control: (provided, state) => ({
          ...provided,
          height: '52px',
          borderRadius:10,
          backgroundColor: "#03081f",
          border:'none'
         
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height: '52px',
          position: 'absolute',
          right: 0,
          top: 0,
          color:'#fff' 
        }),    
        singleValue: (provided, state) => ({
          ...provided,
          color: "#fff"
        }),
        menuList: base => ({
            ...base,
            // kill the white space on first and last option
            padding: 0
          })
      };
    componentDidMount() {
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        console.log("nextProps",nextProps.auth != undefined
            && nextProps.auth.updateperpetual != undefined
            && nextProps.auth.updateperpetual.data != undefined
            && nextProps.auth.updateperpetual.data.message != undefined
            && nextProps.auth.updateperpetual.data.success == true,nextProps)
        if (nextProps.record) {
            console.log(nextProps,'recorddddddddddddddddddddddddddd')
            this.setState({
                id: nextProps.record._id,
                tickerroot: nextProps.record.tiker_root,
                hidden: nextProps.record.tiker_root,
                initial_margin: nextProps.record.initial_margin,
                maint_margin: nextProps.record.maint_margin,
                interest_base_symbol: nextProps.record.interest_base_symbol,
                interest_quote_symbol: nextProps.record.interest_quote_symbol,
                funding_premium_symbol: nextProps.record.funding_premium_symbol,
                risk_limit: nextProps.record.risk_limit,
                risk_step: nextProps.record.risk_step,
                minpriceincrement: nextProps.record.minpriceincrement,
                maxpriceincrement: nextProps.record.maxpriceincrement,
                contract_size: nextProps.record.contract_size,
                minquantity: nextProps.record.minquantity,
                maxquantity: nextProps.record.maxquantity,
                mark_price: nextProps.record.mark_price,
                leverage: nextProps.record.leverage,
                maker_rebate: nextProps.record.maker_rebate,
                dailyinterest: nextProps.record.dailyinterest,
                taker_fees: nextProps.record.taker_fees,
                floatingDigits: nextProps.record.floatingDigits,
                markuppercentage: nextProps.record.markuppercentage,
                lotsize: nextProps.record.lotsize,
                funding_interval: nextProps.record.funding_interval,
                first_currency: { label: nextProps.record.first_currency, value: nextProps.record.first_currency },
                second_currency: { label: nextProps.record.second_currency, value: nextProps.record.second_currency },
                selectedOption: nextProps.record.first_currency,
                selectedOption1: nextProps.record.second_currency,
                bot: { label: nextProps.record.botstatus, value: nextProps.record.botstatus },
                selectedbot: nextProps.record.botstatus,
                maxPrice: nextProps.record.maxPrice,
                minPrice: nextProps.record.minPrice,
            })
        }
        if (nextProps.errors) {
            console.log(nextProps.errors,"nextProps.errors")
            $("#update-perpetual-modal").find(".text-danger").show();
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth != undefined
            && nextProps.auth.updateperpetual != undefined
            && nextProps.auth.updateperpetual.data != undefined
            && nextProps.auth.updateperpetual.data.message != undefined
            && nextProps.auth.updateperpetual.data.success == true) {
                console.log("if")
                var btn = document.getElementById('close')
                btn.click()
            // $('#update-perpetual-modal').modal('hide');
            toast(nextProps.auth.updateperpetual.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            nextProps.auth.updateperpetual = "";
        }
    }
    getData() {
        axios
            .get(url + "api/perpetual-data-first")
            .then(res => {
                console.log('perpetual-data-first',res.data);
                var currencyarray = [];
                res.data.map((item, i) => {
                    const name = item.currencySymbol;
                    const value = item.currencySymbol;
                    const obj = { 'value': name, 'label': value };
                    currencyarray.push(obj);
                });
                //console.log(currencyarray,'currencyarray');
                this.setState({ first_currency1: currencyarray, email_assigned: true });
            })
            .catch()
        //console.log(this.props,'authget1');
    };
    onChange = e => {
        $("#update-perpetual-modal").find(".text-danger").show();
        this.setState({ [e.target.id]: e.target.value });
    };
    handleChange = selectedOption => {
        this.setState({ first_currency: selectedOption });
        //console.log(`Option selected:`, selectedOption );
    };
    handleChange1 = selectedOption => {
        this.setState({ second_currency: selectedOption });
        // console.log(`Option selected:`, selectedOption );
    };
    handlebot = selectedbot => {
        this.setState({ bot: selectedbot });
        //console.log(`Option selected:`, selectedOption );
    };

    onPerpetualUpdate = e => {
        e.preventDefault();
        $("#update-spot-modal").find(".text-danger").show();
        console.log(this.state.mark_price,"this.state.mark_price")
        const newcontract = {
            _id: this.state.id,
            tickerroot: this.state.first_currency.value + this.state.second_currency.value,
            maxquantity: this.state.maxquantity,
            minquantity: this.state.minquantity,
            mark_price: this.state.mark_price,
            maker_rebate: this.state.maker_rebate,
            taker_fees: this.state.taker_fees,
            floatingDigits: this.state.floatingDigits.toString(),
            markuppercentage: this.state.markuppercentage,
            hidden: this.state.hidden,
            second_currency: this.state.second_currency.value,
            first_currency: this.state.first_currency.value,
            botstatus: this.state.bot.value,
            maxPrice: this.state.maxPrice,
            minPrice: this.state.minPrice
        };
        console.log("newcontract---------->",newcontract)
        this.props.updatepair(newcontract);
    //    var btn = document.getElementById('close')
    //    btn.click()
    };

    render() {
        const { errors, selectedOption, selectedOption1 } = this.state;
        const { selectedbot } = this.state.bot

        /* const { selectedOption } = this.state.first_currency;
          const { selectedOption1 } = this.state.second_currency;*/
        const options1 = [
            { value: 'USD', label: 'USD' },
            { value: 'INR', label: 'INR' },
        ];

        return (
            <div>
                <div className="modal fade" id="update-spot-modal">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Pair</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onPerpetualUpdate} id="update-pair">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.id}
                                        id="perpetual-update-id"
                                        type="text"
                                        className="d-none" />
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.hidden}
                                        id="hiiden"
                                        type="text"
                                        className="d-none" />
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="tickerroot">Ticker Root</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.tickerroot}
                                                id="tickerroot"
                                                type="text"
                                                error={errors.tickerroot}
                                                className={classnames("form-control", {
                                                    invalid: errors.tickerroot
                                                })} readOnly />
                                            <span className="text-danger">{errors.tickerroot}</span>
                                        </div>
                                    </div>


                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="taker_fees">Taker Fees (%)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.taker_fees}
                                                error={errors.taker_fees}
                                                id="taker_fees"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.taker_fees
                                                })}
                                            />
                                            <span className="text-danger">{errors.taker_fees}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="markuppercentage">Mark up Percentage </label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.markuppercentage}
                                                error={errors.markuppercentage}
                                                id="markuppercentage"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.markuppercentage
                                                })}
                                            />
                                            <span className="text-danger">{errors.markuppercentage}</span>
                                        </div>
                                    </div>


                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="maker_rebate">Maker Rebate (%)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.maker_rebate}
                                                error={errors.maker_rebate}
                                                id="maker_rebate"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.maker_rebate
                                                })}
                                            />
                                            <span className="text-danger">{errors.maker_rebate}</span>
                                        </div>
                                    </div>


                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="mark_price">Market Price</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.mark_price}
                                                error={errors.mark_price}
                                                id="mark_price"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.mark_price
                                                })}
                                            />
                                            <span className="text-danger">{errors.mark_price}</span>
                                        </div>
                                    </div>



                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="maxquantity">Max Order Quantity</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.maxquantity}
                                                error={errors.maxquantity}
                                                id="maxquantity"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.maxquantity
                                                })}
                                            />
                                            <span className="text-danger">{errors.maxquantity}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="minquantity">Min Order Quantity</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.minquantity}
                                                error={errors.minquantity}
                                                id="minquantity"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.minquantity
                                                })}
                                            />
                                            <span className="text-danger">{errors.minquantity}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="maxPrice">Max Price(%)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.maxPrice}
                                                error={errors.maxPrice}
                                                id="maxPrice"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.maxPrice
                                                })}
                                            />
                                            <span className="text-danger">{errors.maxPrice}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="minPrice">Min Price(%)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.minPrice}
                                                error={errors.minPrice}
                                                id="minPrice"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.minPrice
                                                })}
                                            />
                                            <span className="text-danger">{errors.minPrice}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="minquantity">Floating Digits</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.floatingDigits}
                                                error={errors.floatingDigits}
                                                id="floatingDigits"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.floatingDigits
                                                })}
                                            />
                                            <span className="text-danger">{errors.floatingDigits}</span>
                                        </div>
                                    </div>



                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="first_currency">First Currency</label>
                                        </div>
                                        <div className="col-md-9">
                                            {(this.state.first_currency) ?
                                                <Select
                                                    value={this.state.first_currency}
                                                    defaultValue={{ label: this.state.first_currency.label, value: this.state.first_currency.label }}
                                                    onChange={this.handleChange}
                                                    options={this.state.first_currency1}
                                                    styles={this.styles} className="border_blue_select"
                                                /> : ''}
                                            <span className="text-danger">{errors.first_currency}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="second_currency">Second Currency</label>
                                        </div>
                                        <div className="col-md-9">
                                            {(this.state.second_currency) ?
                                                <Select
                                                    value={this.state.second_currency}
                                                    defaultValue={{ label: this.state.second_currency.label, value: this.state.second_currency.label }}
                                                    onChange={this.handleChange1}
                                                    options={options1}
                                                    styles={this.styles} className="border_blue_select"
                                                /> : ''}
                                            <span className="text-danger">{errors.second_currency}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="autobot">Binance Integration</label>
                                        </div>
                                        <div className="col-md-9">
                                            <Select
                                                value={this.state.bot}
                                                defaultValue={{ label: this.state.bot.label, value: this.state.bot.label }}
                                                onChange={this.handlebot}
                                                options={options}
                                                styles={this.styles} className="border_blue_select"

                                            />
                                            <span className="text-danger">{errors.bot}</span>
                                        </div>
                                    </div>



                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" id='close' className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="update-pair"
                                    type="submit"
                                    className="btn btn-primary"
                                    >
                                    Update Contract
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

PairUpdateModal.propTypes = {
    updatePerpetual: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updatepair }
)(withRouter(PairUpdateModal));
