import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { stakingupdate } from "../../actions/staking";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import $ from "jquery";
import { Editor } from "@tinymce/tinymce-react";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import keys from "../../actions/config";
import Switch from "react-switch";


const url = keys.baseUrl;

const options = [
  { value: "Crypto", label: "Crypto" },
  { value: "Token", label: "Token" },
  { value: "Fiat", label: "Fiat" },
];

class StakingUpdateModal extends React.Component {
  constructor(props) {
    super(props);
    $("#update-staking-modal").find(".text-danger").hide();

    this.state = {
      id: this.props.record._id,
      stakingName: this.props.record.stakingName,
      minimum: this.props.record.minimum,
      maximum: this.props.record.maximum,
      errors1: {},
    };
    this.handleChange = this.handleChange.bind(this);
  }

  styles = {
    option: (styles, {isFocused, isSelected}) => ({
      ...styles,
      color: "white",
      background: isFocused
          ? '#1d296a'
          : isSelected
              ? '#113853'
              : "#81c8f6",
      zIndex: 1
  }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '52px',
      padding: '0 6px',
      backgroundColor: "#03081f",
      borderColor: '#81c8f6',
    borderRadius: 10,
    borderStyle: 'solid',
    borderWidth: '1px'
     
    }),
    control: (provided, state) => ({
      ...provided,
      height: '52px',
      borderRadius:10,
      backgroundColor: "#03081f",
      border:'none'
     
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '52px',
      position: 'absolute',
      right: 0,
      top: 0,
      color:'#fff' 
    }),    
    singleValue: (provided, state) => ({
      ...provided,
      color: "#fff"
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0
    })
  };
  componentWillReceiveProps(nextProps) {
    console.log("this.sporor", nextProps);
    if (nextProps.record) {
      this.setState({
        id: nextProps.record._id,
        stakingName: nextProps.record.stakingName,
        minimum: nextProps.record.minimum,
        maximum: nextProps.record.maximum,
        errors1: "",
      });
    }
    if (nextProps.errors) {
      $("#update-staking-modal").find(".text-danger").show();
      this.setState({
        errors1: nextProps.errors,
      });
    } else {
      this.setState({
        errors1: "",
      });
    }
    // console.log(nextProps)
    if (
      nextProps.auth !== undefined &&
      nextProps.auth.stakingupdate !== undefined &&
      nextProps.auth.stakingupdate.data !== undefined &&
      nextProps.auth.stakingupdate.data.message !== undefined &&
      nextProps.auth.stakingupdate.data.success
    ) {
      $("#update-staking-modal").modal("hide");
      toast(nextProps.auth.stakingupdate.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      nextProps.auth.stakingupdate = "";
      this.setState({
        errors1: "",
      });
    }
  }

  handleChange = (selectedOption) => {
    this.setState({ type: selectedOption });
    // this.setState({stakingType:selectedOption.value})
    //console.log(`Option selected:`, selectedOption );
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
    $("#update-staking-modal").find(".text-danger").show();
    // if (e.target.id === 'staking-update-stakingName') {
    //     this.setState({ stakingName: e.target.value });
    // }
    // if (e.target.id === 'staking-update-stakingSymbol') {
    //     this.setState({ stakingSymbol: e.target.value });
    // }
  };
  componentDidMount() {
    this.setState({ errors1: "" });
    if (this.props.record.type == "Token") {
      this.setState({ show: true });
    }
  }
  onStakingUpdate = (e) => {
    console.log("hey you", this.state);
    e.preventDefault();
      $("#update-staking-modal").find(".text-danger").show();
      const newFaq = {
        _id: this.state.id,
        stakingName: this.state.stakingName,
        minimum: this.state.minimum,
        maximum: this.state.maximum,
        hidden: this.state.hidden,
        hidden1: this.state.hidden1,
      };
      this.props.stakingupdate(newFaq);
  };

  render() {
    const { errors1, errors } = this.state;
    return (
      <div>
        <div className="modal fade" id="update-staking-modal">
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Update Staking</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form
                  noValidate
                  onSubmit={this.onStakingUpdate}
                  id="update-staking"
                >
                  <input
                    onChange={this.onChange}
                    value={this.state.id}
                    id="staking-update-id"
                    type="text"
                    className="d-none"
                  />
                  {/* <input
                    onChange={this.onChange}
                    value={this.state.hidden}
                    id="hidden"
                    type="text"
                    className="d-none"
                  />
                  <input
                    onChange={this.onChange}
                    value={this.state.hidden1}
                    id="hidden1"
                    type="text"
                    className="d-none"
                  /> */}

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="currencyRef">Staking</label>
                    </div>
                    <div className="col-md-9">
                      {/* <Select
                        onChange={this.handleChange}
                        options={this.state.records_currency_options}
                        label="Single select"
                      /> */}
                      {/* {this.state.type ? (
                        <Select
                          value={this.state.type}
                          defaultValue={{
                            label: this.state.type.label,
                            value: this.state.type.label,
                          }}
                          onChange={this.handleChange}
                          options={options}
                          styles={this.styles} className="border_blue_select"
                        />
                      ) : (
                        ""
                      )} */}
                      <span className="text-danger">{errors1.currencyRef}</span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="minimum">Minimum Amount</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.minimum}
                        error={errors1.minimum}
                        id="minimum"
                        type="text"
                        className={classnames("form-control", {
                          invalid: errors1.minimum,
                        })}
                      />
                      <span className="text-danger">{errors1.minimum}</span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="maximum">Maximum Amount</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.maximum}
                        error={errors1.maximum}
                        id="maximum"
                        type="text"
                        className={classnames("form-control", {
                          invalid: errors1.maximum,
                        })}
                      />
                      <span className="text-danger">{errors1.maximum}</span>
                    </div>
                  </div>

                  {/* <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="flexible">
                        <span>Flexible</span>
                      </label>
                    </div>
                    <div className="col-md-9">
                      <Switch onChange={this.state.switchAChange } checked={this.state.flexible} />
                    </div>
                  </div> */}

                  {/* {
                    this.state.periodList.map((x, i) => {
                      return (
                        <div className="row mt-2">
                          <div className="col-md-3">
                            <label htmlFor="minimum">Period {i+1}</label>
                          </div>
                          <div className="box row col-md-9">
                            <div className="col-md-3">
                              <input
                                name="days"
                                placeholder="Enter Days"
                                value={x.days}
                                onChange={e => this.handleInputChange(e, i)}
                                className={classnames("form-control")}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                name="apy"
                                placeholder="Enter apy"
                                value={x.apy}
                                onChange={e => this.handleInputChange(e, i)}
                                className={classnames("form-control")}
                              />
                            </div>
                            <div className="btn-box col-md-3">
                              {
                                periodList.length !== 1 &&<button
                                className="btn btn-primary"
                                onClick={() => this.handleRemoveClick(i)}>Remove</button>
                              }
                              {
                                periodList.length - 1 === i && <button type="button" className="btn btn-primary" onClick={this.handleAddClick}>Add</button>
                              }
                            </div>
                          </div>
                        </div>
                        )
                      })
                     } */}
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  form="update-staking"
                  type="submit"
                  className="btn btn-primary"
                >
                  Update Staking
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

StakingUpdateModal.propTypes = {
  stakingupdate: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { stakingupdate })(
  withRouter(StakingUpdateModal)
);
