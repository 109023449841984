import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Dashboard from "./components/pages/Dashboard";
import React, { Component } from 'react';
import Login from "./components/auth/Login";
import Forgot from "./components/auth/Forgot";
import ResetPassword from "./components/auth/resetPassword";
import NotFound from "./components/layout/NotFound";
import { Provider } from "react-redux";
import PrivateRoute from "./components/private-route/PrivateRoute";
import Register from "./components/auth/Register";
import store from "./store";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";

import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootstrap/dist/js/bootstrap';
import '../node_modules/font-awesome/css/font-awesome.css';
import '../node_modules/jquery/dist/jquery.min';
import '../node_modules/popper.js/dist/popper';
import User from "./components/pages/Users";
import Bonus from "./components/pages/Bonus";
import Emailtemplates from "./components/pages/Emailtemplates";
import Pairmanagement from "./components/pages/Pairmanagement";
import Cms from "./components/pages/Cms";
import Profile from "./components/pages/Profile.jsx";
import Settings from "./components/pages/Settings";
import Changepassword from "./components/pages/Changepassword";
import Faq from "./components/pages/Faq";
import Withdraw from "./components/pages/Withdraw";
import Deposit from "./components/pages/Deposit";
import Userbalance from "./components/pages/Userbalance";
import Liquidated from "./components/pages/Liquidated";
import Perpetual from "./components/pages/Perpetual";
import Contactus from "./components/pages/Contactus";
import Chat from "./components/pages/Chat";
import Newsletter from "./components/pages/Newsletter";
import Support from "./components/pages/Support";
import Support_reply from "./components/partials/SupportReplyModal";
import Currency from "./components/pages/Currency";
import TradeHistory from "./components/pages/Tradehistory";
import Closedpositions from "./components/pages/Closedpositionshistory";
import OrderHistory from "./components/pages/Orderhistory";
import FeeSettings from "./components/pages/Feesettings.jsx"
import Assets from "./components/pages/AssetsExchange.jsx"
import Category from "./components/pages/category.jsx"
import Subcategory from "./components/pages/Subcategory.jsx"
import Blocklist from "./components/pages/Blocklist.jsx";
import Staking from "./components/pages/Staking";
import HelpCentreCategory from "./components/pages/HelpCentrecategory.jsx"
import HelpCentreArticle from "./components/pages/HelpcentreArticle.jsx"
import Kyc from "./components/pages/Kyc";
import kycupdate from "./components/partials/kycupdate.jsx";
import Tradingbot from "./components/pages/Tradingbot"
import Launchpad from "./components/pages/Launchpad";
import copyTradeSettings from "./components/pages/copyTrade/copyTradeSettings";
import copyTradeApplications from "./components/pages/copyTrade/copyTradeApplications";
import CommissionHistory from "./components/pages/CommissionHistory";
import PerpetualOrderHistory from './components/pages/perputualOrderHoitory'
import PerpetualTradeHistory from './components/pages/perpetualTradeHistory'
import LoginHistory from './components/pages/LoginHistory'


if (localStorage.adminJwtToken) {
    const token = localStorage.adminJwtToken;
    setAuthToken(token);
    const decoded = jwt_decode(token);
    store.dispatch(setCurrentUser(decoded));
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
        store.dispatch(logoutUser());
        window.location.href = "./login";
    }
}

class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <Router basename={'/'}>
                    <div className="App">
                        <Switch>
                            <Route exact path="/" component={Login} />
                            <Route exact path="/register" component={Register} />
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/forgot" component={Forgot} />
                            <Route exact path="/resetPassword/:id" component={ResetPassword} />
                            <Switch>
                                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                                <PrivateRoute exact path="/users" component={User} />
                                <PrivateRoute exact path="/bonus" component={Bonus} />
                                <PrivateRoute exact path="/assets" component={Assets} />
                                <PrivateRoute exact path="/emailtemplates" component={Emailtemplates} />
                                <PrivateRoute exact path="/cms" component={Cms} />
                                <PrivateRoute exact path="/profile" component={Profile} />
                                <PrivateRoute exact path="/settings" component={Settings} />
                                <PrivateRoute exact path="/changepassword" component={Changepassword} />
                                <PrivateRoute exact path="/faq" component={Faq} />
                                <PrivateRoute exact path="/perpetual" component={Perpetual} />
                                <PrivateRoute exact path="/contactus" component={Contactus} />
                                <PrivateRoute exact path="/chat" component={Chat} />
                                <PrivateRoute exact path="/newsletter" component={Newsletter} />
                                <PrivateRoute exact path="/support" component={Support} />
                                <PrivateRoute exact path="/support_reply/:id" component={Support_reply} />
                                <PrivateRoute exact path="/currency" component={Currency} />
                                <PrivateRoute exact path="/tradehistory" component={TradeHistory} />
                                <PrivateRoute exact path="/closedpositions" component={Closedpositions} />
                                <PrivateRoute exact path="/orderhistory" component={OrderHistory} />
                                <PrivateRoute exact path="/perpetual-Trade-History" component={PerpetualTradeHistory} />
                                <PrivateRoute exact path="/perpetual-Order-History" component={PerpetualOrderHistory} />
                                <PrivateRoute exact path="/feesettings" component={FeeSettings} />
                                <PrivateRoute exact path="/Userbalance" component={Userbalance} />
                                <PrivateRoute exact path="/withdraw" component={Withdraw} />
                                <PrivateRoute exact path="/deposit" component={Deposit} />
                                <PrivateRoute exact path="/liquidated" component={Liquidated} />
                                <PrivateRoute exact path="/category" component={Category} />
                                <PrivateRoute exact path="/subcategory" component={Subcategory} />
                                <PrivateRoute exact path="/blocklist" component={Blocklist} />
                                <PrivateRoute exact path="/Pairmanagement" component={Pairmanagement} />
                                <PrivateRoute exact path="/HelpCentreCategory" component={HelpCentreCategory} />
                                <PrivateRoute exact path="/HelpCentreArticle" component={HelpCentreArticle} />
                                <PrivateRoute exact path="/Tradingbot" component={Tradingbot} />
                                <PrivateRoute exact path="/Staking" component={Staking} />
                                <PrivateRoute exact path="/launchpad" component={Launchpad} />
                                <PrivateRoute exact path="/kyc" component={Kyc} />
                                <PrivateRoute exact path="/copytrade" component={copyTradeApplications} />
                                <PrivateRoute exact path="/copytrade/settings" component={copyTradeSettings} />
                                <PrivateRoute exact path="/copytrade/applications" component={copyTradeApplications} />
                                <PrivateRoute exact path="/loginhistory" component={LoginHistory} />
                                <PrivateRoute exact path="/commissionhistory" component={CommissionHistory} />
                                <PrivateRoute
                                    exact
                                    path="/kycupdate/:id"
                                    component={kycupdate}
                                />
                            </Switch>
                            <Route exact path="*" component={NotFound} />
                        </Switch>
                    </div>
                </Router>
            </Provider>
        );
    }
}

export default App;
