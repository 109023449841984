// import config
import axios from '../config/axios';
import keys from "./config";
const url = keys.baseUrl;

export const spotOrderHistory = async (data) => {

    try {
        let respData = await axios({
            'method': 'get',
            'url': `/adminapi/spotOrderHistory`,
            'params': data
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const spotTradeHistory = async (data) => {

    try {
        let respData = await axios({
            'method': 'get',
            'url': `/adminapi/spotTradeHistory`,
            'params': data
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const perpetualOrderHistory = async (data) => {

    try {
        let respData = await axios({
            'method': 'get',
            'url': `${url}adminapi/perpetualOrderHistory`,
            'params': data
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const perpetualTradeHistory = async (data) => {

    try {
        let respData = await axios({
            'method': 'get',
            'url': `${url}adminapi/perpetualTradeHistory`,
            'params': data
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}
