import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import SupportReplyModal from "../partials/SupportReplyModal";
import { toast, ToastContainer } from "react-toastify";
import keys from "../../actions/config";
import { Loader } from "react-full-page-loader-overlay";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import { TradeHistoryExportcsv } from "../../actions/userActions";
import "react-datepicker/dist/react-datepicker.css";
const url = keys.baseUrl;
class tradehistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            total_pages: 0,
            records: [],
            count: 0,
            loadershow: false,
            startDate: "",
            endDate: ""
        };
        this.columns = [
            {
                key: "pairname",
                text: "Contracts",
                className: "pairname",
                align: "left",
                sortable: true,
                width: 200,
            }, {
                key: "Buyemail",
                text: "Buy Email",
                className: "name",
                align: "left",
                sortable: true,
                width: 200,
            }, {
                key: "Sellemail",
                text: "Sell Email",
                className: "name",
                align: "left",
                sortable: true,
                width: 200,
            }, {
                key: "filledtype",
                text: "Filled Type",
                className: "filledtype",
                align: "left",
                sortable: true,
                width: 200,
            }, {
                key: "filledAmount",
                text: "Quantity",
                className: "filledAmount",
                align: "left",
                sortable: true,
                width: 200,
            },
            {
                key: "Fee",
                text: "Fee",
                className: "Fee",
                align: "left",
                sortable: true,
                width: 200,
            }, {
                key: "Price",
                text: "Price",
                className: "Price",
                align: "left",
                sortable: true,
                width: 200,
            }, {
                key: "orderValue",
                text: "Order Value",
                className: "orderValue",
                align: "left",
                sortable: true,
                width: 200,
            }, {
                key: "Remaining",
                text: "Remaining",
                className: "Remaining",
                align: "left",
                sortable: true,
                width: 200,
            }, {
                key: "orderType",
                text: "Order Type",
                className: "orderType",
                align: "left",
                sortable: true,
                width: 200,
            }, {
                key: "_id",
                text: "#",
                className: "_id",
                align: "left",
                sortable: true,
                width: 200,
            }, {
                key: "createdDate",
                text: "Date",
                className: "createdDate",
                align: "left",
                sortable: true,
                width: 200,
            },




        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Order",
            no_data_text: 'No Records found!',
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: false,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };
        this.downloadCSV = this.downloadCSV.bind(this)
    }
    componentDidMount() {
        this.getData()
    };

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }
    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }
        }).join('&');

        this.getData(queryString);
    }
    getData(queryString = "") {
        // this.setState({loadershow:true})
        let urldet = url + "api/trade_history/spot?" + queryString;
        axios
            .get(urldet)
            .then(res => {
                console.log(res, 'ressssss')
                this.setState({
                    // total: res.data.total,
                    records: res.data.result,
                    // count: res.data.totalcount,
                    // loadershow: false

                })
            })
            .catch()
    }
    

    // async handleChange(type,date){
    //     console.log("handleChange",type,"======", "date",date);
    //     if(type == "startDate" || type == "startdate"){
    //         this.setState({startDate: date})
    //         const filterData = await axios.post(url + "api/trade_history_datefilter/spot",{date: date});
    //         console.log("p",filterData)
    //     }else if(type == "enddate" || type == "enddate")
    //     {
    //         this.setState({endDate: date})
    //     }else{
    //         console.log("Sorry no type is here");
    //     }
    // }
    async sortByDate() {
        if (this.state.startDate != "" || this.state.endDate != "") {
            const formData = new FormData();
            formData.append("from", this.state.startDate);
            formData.append("to", this.state.endDate);
            formData.append("type", "search");
            console.log("formData------dfd--",formData);
            const data = await axios.post(url + "api/trade_history_datefilter",formData);
            console.log("data_data_data4444",data.data);
            this.setState({ records: data.data.result})
        } else {
            console.log("data first ");
        }
        
    }
    async downloadCSV() {
        let data = {
            export: 'csv',
            from: this.state.startDate,
            to: this.state.endDate,
        }
        const download = await TradeHistoryExportcsv(data);
        console.log("downloadCSV_downloadCSV", download);
    }

    render() {

        const { records } = this.state
        return (
            <div>
                {/* <Loader centerBorder="#66B7F5" show={this.state.loadershow}  /> */}
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h3 className="mt-2 text-secondary">Trade History</h3>

                            <div className="mt-4">
                                <button onClick={this.downloadCSV} className='btn btn-rec mr-2 mb-2' style={{ width: '118px', height: '35px', fontSize: '13px' }}>Download(CSV)</button>
                                {/* <button onClick={this.exportPDF} className='btn btn-rec mr-2 mb-2' style={{ width: '118px', height: '35px', fontSize: '13px' }}>Download(CSV)</button>
                            <button onClick={this.exportPDF} className='btn btn-rec mr-2 mb-2' style={{ width: '118px', height: '35px', fontSize: '13px' }}>Download(XLS)</button> */}
                            </div>
                            <div>
                                <p className="filter_date_text">Filter based on date</p>
                                <div className="row">
                                    <div className="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 mt-2">
                                        <label>From Date</label>
                                        <DatePicker
                                            selected={this.state.startDate}
                                            maxDate={new Date()}
                                            onChange={(date) => { this.setState({ startDate: date}) }}
                                            dateFormat="MMMM d, yyyy"
                                            className={classnames("form-control", {
                                            })}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 mt-2">
                                        <label>To Date</label>
                                        <DatePicker
                                            selected={this.state.endDate}
                                            maxDate={new Date()}
                                            // onChange={(date) => { this.setState({ endDate: date}); this.handleChange("endDate", date) }}
                                            onChange={(date) => { this.setState({ endDate: date});}}
                                            minDate={this.state.startDate}
                                            dateFormat="MMMM d, yyyy"
                                            className={classnames("form-control", {
                                            })}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 mt-2">
                                    <button className="btn btn-light mt_seact_date"  onClick={()=> {this.sortByDate()}}>Search</button>
                                    </div>
                                </div>
                            </div>
                            <div className="resonsive_table">
                                <ReactDatatable
                                    className="table table-bordered table-striped trade_history_table"
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                    dynamic={true}
                                    total_record={this.state.count}
                                    onChange={this.tableChangeHandler}
                                />
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        );
    }

}

tradehistory.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(tradehistory);
