import React, { Component, Fragment } from "react";
import Navbar from "../../partials/Navbar";
import classnames from "classnames";
import Sidebar from "../../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Editor } from '@tinymce/tinymce-react';
import {CKEditor} from 'ckeditor4-react'
import {
  updateTableDataDynamic,
  getTableDataDynamic
} from "../../../actions/userActions";

import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import keys from "../../../actions/config";
import { withRouter } from "react-router-dom";

const url = keys.baseUrl;
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minimumDeposit:"",
      notes:"",
      autoUnFolHrs:0,
      errors: {}
    };
    this.handleEditorChange = this.handleEditorChange.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    var dynobj = {}
    dynobj.find = {type: 'main'};
    dynobj.table = {name: 'CopyTradeSettingsTable'};
    dynobj.return = {name: 'CopyTradeSettingsData'};
    this.props.getTableDataDynamic(dynobj);
  }

  updateSetting = e => {
    e.preventDefault();
    var dynobj = {}
    dynobj.update = {
      minimumDeposit: this.state.minimumDeposit,
      notes: this.state.notes,
      autoUnFolHrs :this.state.autoUnFolHrs
    };
    dynobj.table = {name: 'CopyTradeSettingsTable'};
    dynobj.find = {type: 'main'};
		dynobj.return = {name: 'CopyTradeSettingsData'};
    this.props.updateTableDataDynamic(dynobj);
  };

  handleEditorChange(e) {
    console.log(e.editor.getData())
    this.setState({ "notes":e.editor.getData() });
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({errors: nextProps.errors});
    } else {
      this.setState({errors: {}});
    }
    if(typeof nextProps.auth!='undefined'){
      if(typeof nextProps.auth.updatesettings!='undefined'){
        if(typeof nextProps.auth.updatesettings.data!='undefined'){
          var data = nextProps.auth.updatesettings.data;
          if(typeof data.toast!='undefined'){
            var toastData = data.toast;
            toast(toastData.message, {position: toast.POSITION.TOP_CENTER});
          }
          if(typeof data.CopyTradeSettingsData!='undefined'){
            var CopyTradeSettingsData = data.CopyTradeSettingsData;
            if(typeof CopyTradeSettingsData.minimumDeposit!='undefined'){
              console.log(CopyTradeSettingsData.notes,"notes")
              this.setState({
                minimumDeposit: CopyTradeSettingsData.minimumDeposit,
                notes: CopyTradeSettingsData.notes
              });
            }
          }
        }
      }
    }
  }

  render() {
    const { errors,notes } = this.state;
    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <h3 className="mt-2 text-secondary">Become a trader settings</h3>
              <form
                noValidate
                onSubmit={this.updateSetting}
                id="update-profile"
              >
                <div className="row mt-5">
                  <div className="col-md-3">
                    <label htmlFor="name">Minimum Balance (USDT)</label>
                  </div>
                  <div className="col-md-6 col-lg-7">
                    <input
                      onChange={this.onChange}
                      value={this.state.minimumDeposit}
                      id="minimumDeposit"
                      type="text"
                      error={errors.name}
                      className={classnames("form-control", {
                        invalid: errors.name
                      })}
                    />
                    <span className="text-danger">{errors.name}</span>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-md-3">
                    <label htmlFor="name">Auto unfollow in hours</label>
                  </div>
                  <div className="col-md-6 col-lg-7">
                    <input
                      onChange={this.onChange}
                      value={this.state.autoUnFolHrs}
                      id="autoUnFolHrs"
                      type="number"
                      error={errors.autoUnFolHrs}
                      className={classnames("form-control", {
                        invalid: errors.autoUnFolHrs
                      })}
                    />
                    <span className="text-danger">{errors.autoUnFolHrs}</span>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="name">Notes</label>
                  </div>
                  <div className="col-md-6 col-lg-7">
                    {/* <Editor
                      apiKey={keys.editor.apiKey}
                      init={keys.editor.init}
                      initialValue={this.state.notes}
                      value={this.state.notes}
                      id="notes"
                      onEditorChange={this.handleEditorChange}
                    /> */}
                    {notes && <CKEditor
                      config={{
                        //extraAllowedContent: 'div(*)',
                        allowedContent: true,
                        height: 500,
                      }}
                      initData={notes}
                      onChange={this.handleEditorChange}
                    />}
                  </div>
                </div>
              </form>
              <br />
              <button
                form="update-profile"
                type="submit"
                className="btn btn-primary"
              >Update</button>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  }
}

Profile.propTypes = {
  getTableDataDynamic:PropTypes.func.isRequired,
  updateTableDataDynamic: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, { updateTableDataDynamic, getTableDataDynamic })(withRouter(Profile));
