import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import {Link} from "react-router-dom";
import {faUserAlt} from "@fortawesome/free-solid-svg-icons/faUserAlt";
import { toast,ToastContainer } from "react-toastify";
import TradingViewWidget, { Themes } from 'react-tradingview-widget';
import keys from "../../actions/config"
import Papa from "papaparse";
import axios from "axios";
import { Button } from "bootstrap";

import "react-toastify/dist/ReactToastify.css";

var CanvasJSReact = require('../../canvasjs.react');
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const url = keys.baseUrl;
var filename = "";
// import io from 'socket.io-client'
class Dashboard extends Component {
   
    constructor(props) {
        super(props);
        console.log(props?.location?.state?.data, "PROPS DATA", props);

        this.state = {
            filename: "",
            // valuesArray: [],
            values: [],
            spotValues:[],
            spotFileName: []
        };
        // this.socket = io(keys.baseUrl);
        // this.socket.on('TRADEBIN', (result) => {
        //     console.log()
        //   })
      
    }

    onLogoutClick = e => {
        e.preventDefault();
        this.props.logoutUser();
    };

    
    // componentDidMount() {
    //     console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
    //     var pagelinkofset = document.querySelectorAll(".list-group-item-action.active")[0].offsetTop;
    //     document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset);
    //   }


    changeHandler = async (event) => {
        let splitFile = event.target.files[0].name.split(".");
        console.log("splitFile",splitFile)
        if (splitFile[splitFile.length - 1] != "csv") {
            toast.error('Select your csv file !', {
                position: toast.POSITION.TOP_CENTER
            });
            return false
        }
        const valuesArray = [];
        this.setState({ 'filename': event.target.files[0].name })
        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: async (results) => {
                // this.setState({ valuesArray: results.data })

                // Iterating data to get column name and their values
                results.data.map((d) => {
                    console.log("===============",Object.keys(d));
                    valuesArray.push(Object.values(d));
                });

                // Filtered Values
                this.setState({ values: valuesArray })
            },
        });
    }

    changeHandlerSpot = async (event) => {
        let splitFile = event.target.files[0].name.split(".");
        console.log("splitFile",splitFile)
        if(splitFile[splitFile.length - 1] != "csv"){
            toast.error('Select your csv file !', {
                position: toast.POSITION.TOP_CENTER
            });
            return false
        }
        const valuesArray = [];
        this.setState({ 'spotFileName': event.target.files[0].name })
        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: async (results) => {
                // this.setState({ valuesArray: results.data })

                // Iterating data to get column name and their values
                results.data.map((d) => {
                    valuesArray.push(Object.values(d));
                });
                console.log("VALUEARRAY : ", valuesArray);
                // Filtered Values
                this.setState({ spotValues : valuesArray })
            },
        });
    }


    onsubmit = async () => {
        console.log("VALUEARRAY : ", this.state.values);
        if (this.state.values.length > 0) {
            if (this.state.spotValues.length > 250) {
                toast.error('File is too large !', {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                await axios
                    .post(url + "api/csvFileReader", { csvData: this.state.values })
                    .then(res => {
                        console.log("csvFileReader_res", res.data);
                        toast("Records Added Succesfully", {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    })
                    .catch(err =>
                        console.log("err_csvFileReader", err)
                    );
            }

        } else {
            toast.error('Select your csv file !', {
                position: toast.POSITION.TOP_CENTER
            });
        }

    }

    onSubmitSpot = async () => {
        console.log("VALUEARRAY : ", this.state.spotValues.length);
        if (this.state.spotValues.length > 0) {
          if(this.state.spotValues.length > 250){
            toast.error('File is too large !', {
                position: toast.POSITION.TOP_CENTER
            }); 
          }else{
            await axios
            .post(url + "api/csvSpotFileReader", { csvData: this.state.spotValues })
            .then(res => {
                console.log("csvFileReader_res", res.data);
                toast("Records Added Succesfully", {
                    position: toast.POSITION.TOP_CENTER,
                });
            })
            .catch(err =>
                console.log("err_csvFileReader", err)
            );
          }
        }else{
            toast.error('Select your csv file !', {
                position: toast.POSITION.TOP_CENTER
            });
        }

    }


    render() {

        //const { user } = this.props.auth;
        return (
            <div>
                {console.log("FILE",this.state.filename)}
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h3 className="mt-2 text-secondary">Dashboard</h3>
                            <div className="row px-0 px-md-2 mt-4">
                                <div className="col-sm-6 col-lg-4 p-sm-2 mb-4 mb-sm-0">
                                    <div className="card text-white" style={{backgroundColor : "cornflowerblue"}}>
                                        <div className="card-body pb-4">
                                            <h5 className="card-title">Users</h5>
                                            <p className="card-text mb-4">With supporting text below as a natural lead-in to
                                                additional content.</p>
                                            <Link to="/users" className="btn btn-light"><FontAwesomeIcon className="text-primary" icon={faUserAlt}/> Go to Users</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-4 p-sm-2 mb-4 mb-sm-0">
                                    <div className="card text-white" style={{backgroundColor: "cadetblue"}}>
                                        <div className="card-body pb-4">
                                            <h5 className="card-title">Enquires</h5>
                                            <p className="card-text mb-4">With supporting text below as a natural lead-in to
                                                additional content.</p>
                                            <Link to="/contactus" className="btn btn-light"><FontAwesomeIcon className="text-primary" icon={faUserAlt}/> Go to Contact</Link>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className="row px-0 px-md-2">
                                <div className="col-sm-6 col-lg-4 p-sm-2 mb-4 mb-sm-0">
                                    <div className="card text-white" style={{backgroundColor: "cadetblue"}}>
                                        <div className="card-body pb-4">
                                            <h5 className="card-title">Add Perpetual Pair</h5>
                                            <p className="card-text mb-4">Upload your csv </p>
                                                <label class="custom-file-upload">
                      <input type="file"   accept=".CSV"  onChange={this.changeHandler} />
                     { this.state.filename == "" || undefined ? "Choose File" : this.state.filename }
                     </label>
                     <button className="btn btn-light mt-3" onClick={()=>{this.onsubmit()}}>submit</button>
                                        </div>
                                        
                                    </div>
                                </div>

                                <div className="col-sm-6 col-lg-4 p-sm-2 mb-4 mb-sm-0">
                                    <div className="card text-white" style={{backgroundColor: "cadetblue"}}>
                                        <div className="card-body pb-4">
                                            <h5 className="card-title">Add Spot Pair</h5>
                                            <p className="card-text mb-4">Upload your csv </p>
                                                <label class="custom-file-upload">
                      <input type="file"   accept=".CSV"  onChange={this.changeHandlerSpot} />
                     { this.state.spotFileName == "" || undefined ? "Choose File" : this.state.spotFileName }
                     </label>
                     <button className="btn btn-light mt-3" onClick={()=>{this.onSubmitSpot()}}>submit</button>
                                        </div>
                                        
                                    </div>
                                </div>
                                </div>

                              

                               
                            </div>

                        </div>

                    </div>
                    <ToastContainer autoClose={2000}/>
                </div>
        );
    }
}

Dashboard.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { logoutUser }
)(Dashboard);
