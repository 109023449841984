import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Select from "react-select";
import {Modal,Button} from 'react-bootstrap/';
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer} from "react-toastify";
import $ from 'jquery';
import keys from "../../actions/config";
const url = keys.baseUrl;

class Userbalance extends Component {
    constructor(props) {
        super(props);
        this.columns = [

            {
                key: "userId",
                text: "User Name",
                className: "userId",
                align: "left",
                sortable: true,
            },
            {
                key: "currencySymbol",
                text: "Currency",
                className: "currency",
                align: "left",
                sortable: true,
            },
            {
                key: "spotwallet",
                text: "Spot Wallet",
                className: "balance",
                align: "left",
                sortable: true,

            },
            {
                key: "balance",
                text: "Derivative Wallet",
                className: "balance",
                align: "left",
                sortable: true,

            },

        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Userbalance",
            no_data_text: 'No Userbalance found!',
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
                records: [],
                showDetails : false,
                id: '',
                status : '',
                transferamount: '',
                cryptoType: '',
                userId: '',
                receiveraddress: '',
                tagid: '',
                errors: ''

        };
        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    };
    showDetails = (record) => {
      this.setState({receiveraddress:record.receiveraddress.address})
      this.setState({tagid:record.receiveraddress.tagid})
      this.setState({id:record._id})
      this.setState({cryptoType:record.cryptoType})
      this.setState({transferamount:record.transferamount})
      this.setState({status:record.status})
      this.setState({showDetails:true})
    }


    handleClosedetails = (record) => {
      this.setState({showDetails:false})
    }
    getData() {
        const data = {
            limit :10,
            page : 1
          }
        axios
            .post(url+"api/Userbalance-data", data)
            .then(res => {
              console.log(res);
                this.setState({ records: res.data.result, totalCount: res.data.count})
            })
            .catch()
    }


    async pageChange(pageData) {
        const data = {
            limit: pageData.page_size,
            page: pageData.page_number,
            search: pageData.filter_value
        }
        const getData = await axios.post(url + "api/Userbalance-data", data);
        this.setState({ records: getData.data.result, totalCount: getData.data.count });

        console.log("OnPageChange_OnPageChange", pageData);
    }

    render() {
        return (
            <div>
            <Modal show={this.state.showDetails} onHide={this.handleClosedetails}  aria-labelledby="contained-modal-title-vcenter" centered>
      				<Modal.Header closeButton>
      				<Modal.Title>Details</Modal.Title>
      				</Modal.Header>
      				<Modal.Body>
      				<div className="popUpSpace">
              <div className="row mt-2">
                  <div className="col-md-3">
                      <label htmlFor="answer">To address</label>
                  </div>
                  <div className="col-md-9">
                      {this.state.receiveraddress}
                  </div>
              </div>

              <div className="row mt-2">
                  <div className="col-md-3">
                      <label htmlFor="answer">Tag id/Memo</label>
                  </div>
                  <div className="col-md-9">
                      {this.state.tagid}
                  </div>
              </div>
              <div className="row mt-2">
                  <div className="col-md-3">
                      <label htmlFor="answer">Transfer Coin</label>
                  </div>
                  <div className="col-md-9">
                      {this.state.cryptoType}
                  </div>
              </div>
              <div className="row mt-2">
                  <div className="col-md-3">
                      <label htmlFor="answer">Transfer Amount</label>
                  </div>
                  <div className="col-md-9">
                      {this.state.transferamount}
                  </div>
              </div>
              <div className="row mt-2">
                  <div className="col-md-3">
                      <label htmlFor="answer">Status</label>
                  </div>
                  <div className="col-md-9">
                      {this.state.status}
                  </div>
              </div>
            	</div>
      				</Modal.Body>
      				<Modal.Footer>
      					<Button variant="danger btnDefaultNewBlue" onClick={this.rejectSubmit}>
      						Reject
      					</Button>
      					<Button onClick={this.confirmSubmit} variant="success btnDefaultNew" >
      						Confirm
      					</Button>
      				</Modal.Footer>
      			</Modal>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h3 className="mt-2 text-secondary">User balance List</h3>
                            <div className="resonsive_table">
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                // responsive={true}
                                dynamic={true}
                                total_record={this.state.totalCount}
                                onChange={this.pageChange.bind(this)}
                            />
                            </div>
                        </div>
                    </div>
                    <ToastContainer/>
                </div>
            </div>
        );
    }

}

Userbalance.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(Userbalance);
