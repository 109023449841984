import axios from "axios";
import {
    GET_ERRORS,
    USER_ADD,
    USER_UPDATE,
    PROFILE_UPDATE,
    PASSWORD_UPDATE,
    SETTINGS_UPDATE,
    REPLY_CONTACT,
    SUPPORT_REPLY,
    Lauchpad_ADD,
    Lauchpad_UPDATE,
    CURRENCY_ADD,
    CONTACT_ADD
} from "./types";
import keys from "./config";
const url = keys.baseUrl;
export const addLaunchpad = (currencyData) => dispatch => {
    console.log("userData!!!!!!",currencyData)
    axios
        .post(url + "api/add-Launchpad", currencyData)
        .then(res =>
            dispatch({
                type: CONTACT_ADD,
                payload: res,
            })
        ).catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};
export const updateLaunchpad = (currencyData) => dispatch => {
    console.log("userData!!!!!!",currencyData)
    axios
        .post(url + "api/update-Launchpad", currencyData)
        .then(res =>
            dispatch({
                type: Lauchpad_UPDATE,
                payload: res,
            })
        ).catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};
