import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateUser } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import keys from "../../actions/config";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';



const url = keys.baseUrl;



class KycViewModal extends React.Component {

    constructor(props) {
        super(props);
        // $("#kyc-view-modal").find(".text-danger").hide();
        console.log(this.props.record, 'tokenTypetokenTypetokenType')
        console.log("KycViewModal_props", props)
        this.state = {
            id: this.props.record.id,
            name: this.props.record.name,
            email: this.props.record.email,
            password: '',
            errors: {},
            individual: false
        };
    }

    componentWillReceiveProps(nextProps) {
        console.log(nextProps, 'nextProps_nextProps')
        this.setState({id: nextProps.record._id})
        this.setState({verifiedstatus: nextProps.record.verifiedstatus})
        if (nextProps.record) {
            console.log("nextProps.record.personalInformation",nextProps?.record?.companyRegistrationDetails)
            if (nextProps.record.personalInformation) {
                this.setState({ individual: true })

                this.setState({
                    //personalinfo
                    firstname: nextProps?.record?.personalInformation?.firstname,
                    lastname: nextProps.record.personalInformation.lastname,
                    dateOfBirth: nextProps.record.personalInformation.dateOfBirth,
                    personalIdentifierCode: nextProps.record.personalInformation.personalIdentifierCode,
                    birthplace: nextProps.record.personalInformation.birthplace,
                    street: nextProps.record.personalInformation.street,
                    city: nextProps.record.personalInformation.city,
                    country: nextProps.record.personalInformation.country,
                    residencystreet: nextProps.record.personalInformation.residencystreet,
                    residencycity: nextProps.record.personalInformation.residencycity,
                    residencycountry: nextProps.record.personalInformation.residencycountry,
                    //financialInfo
                    currentOccupation: nextProps.record.financialInformation.currentOccupation,
                    sourceOfFunds: nextProps.record.financialInformation.sourceOfFunds,
                    othersourcesofincome: nextProps.record.financialInformation.othersourcesofincome,
                    function: nextProps.record.financialInformation.function,
                    employerName: nextProps.record.financialInformation.employerName,
                    companyName: nextProps.record.financialInformation.companyName,
                    natureOfActivity: nextProps.record.financialInformation.natureOfActivity,
                    nameOfEntity: nextProps.record.financialInformation.nameOfEntity,
                    typeOfActivity: nextProps.record.financialInformation.typeOfActivity,
                    specifyActivity: nextProps.record.financialInformation.specifyActivity,
                    monthlyIncome: nextProps.record.financialInformation.monthlyIncome,
                    businessRelationship: nextProps.record.financialInformation.businessRelationship,
                    estimatedAnnualVolume: nextProps.record.financialInformation.estimatedAnnualVolume,
                })
            }
            else {
                this.setState({
                    //entity
                    //companyRegistrationDetails
                    companyname: nextProps?.record?.companyRegistrationDetails?.companyname,
                    regno: nextProps?.record?.companyRegistrationDetails?.regno,
                    regdate: nextProps?.record?.companyRegistrationDetails?.regdate,
                    registration: nextProps?.record?.companyRegistrationDetails?.registration,
                    address: nextProps?.record?.companyRegistrationDetails?.address,
                    businesstype: nextProps?.record?.companyRegistrationDetails?.businesstype,
                    //LegalRepresentative
                    fullname: nextProps?.record?.LegalRepresentative?.fullname,
                    personalIdNo: nextProps?.record?.LegalRepresentative?.personalIdNo,
                    legalStreet: nextProps?.record?.LegalRepresentative?.legalStreet,
                    legalCity: nextProps?.record?.LegalRepresentative?.legalCity,
                    legalCountry: nextProps?.record?.LegalRepresentative?.legalCountry,
                    //companyFinancialDetails
                    companySourceOfFunds: nextProps?.record?.companyFinancialDetails?.companySourceOfFunds,
                    companyAnnualTurnover: nextProps?.record?.companyFinancialDetails?.companyAnnualTurnover,
                    companyBusinessRelationship: nextProps?.record?.companyFinancialDetails?.companyBusinessRelationship,
                    companyAnnualVolume: nextProps?.record?.companyFinancialDetails?.companyAnnualVolume,
                    businessPartnerName: nextProps?.record?.companyFinancialDetails?.businessPartnerName,
                    partnerCountry: nextProps?.record?.companyFinancialDetails?.partnerCountry,
                    businessActivity: nextProps?.record?.companyFinancialDetails?.businessActivity
                })
            }
        }
        // if (nextProps.errors) {
        //     this.setState({
        //         errors: nextProps.errors
        //     });
        // }
        // if (nextProps.auth !== undefined
        //     && nextProps.auth.updatenewuser !== undefined
        //     && nextProps.auth.updatenewuser.data !== undefined
        //     && nextProps.auth.updatenewuser.data.message !== undefined
        //     && nextProps.auth.updatenewuser.data.success) {
        //     $('#kyc-view-modal').modal('hide');
        //     toast(nextProps.auth.updatenewuser.data.message, {
        //         position: toast.POSITION.TOP_CENTER
        //     });
        //     nextProps.auth.updatenewuser = "";
        // }
    }

    // onChange = e => {
    //     if (e.target.id === 'user-update-firstname') {
    //         this.setState({ firstname: e.target.value });
    //     }
    //     // if (e.target.id === 'user-update-email') {
    //     //     this.setState({ email: e.target.value });
    //     // }
    //     // if (e.target.id === 'user-update-password') {
    //     //     this.setState({ password: e.target.value });
    //     // }
    // };

    // onUserUpdate = e => {
    //     e.preventDefault();
    //     const newUser = {
    //         _id: this.state.id,
    //         name: this.state.name,
    //         email: this.state.email,
    //         password: this.state.password
    //     };
    //     this.props.updateUser(newUser);
    // };

    // getData() {
    //     axios
    //         .post(url+"api/user-data")  
    //         .then(res => {
    //             this.setState({ records: res.data})
    //            console.log(res,'reszzzzhhhhhh');
    //         })
    //         .catch()
    // }
    getverifystatus = (verifiedstatus) => {
        try{
            // e.perventDefault()
            const getid = {
                _id: this.state.id,
                 verifiedstatus:verifiedstatus
            };
            console.log('getverifystatus',new Date())
            axios
                .post(url + "api/getverifystatus", getid)
                .then((res) => {
                    if (res.status === 200) {
                        // this.setState({ IDstatus: res.data.updateddata.IDstatus });
                        console.log('getverifystatus2',new Date())
                        toast(res.data.message, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                        this.props.getData();
                        $('#kyc-view-modal').modal('hide');
                    }
                })
                .catch((err)=>{
                    $("#kyc-view-modal").find(".text-danger").show()
                });
        }catch(err){
            console.log(err,'getverifystatus')
            $("#kyc-view-modal").find(".text-danger").show()
        }
    }


    // componentDidMount(){
    //     console.log("checking")
    // }

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="kyc-view-modal">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">KYC Verification</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onUserUpdate} id="update-user">
                                    {this.state.individual === true ?
                                        <>
                                            <h5 className="text">Individual Verification</h5><br></br>
                                            <h6 className="text">Personal Information</h6>
                                            <input
                                                value={this.state.id}
                                                id="user-update-id"
                                                type="text"
                                                className="d-none" />
                                            <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="name">First Name</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input
                                                        value={this.state.firstname}
                                                        id="user-update-name"
                                                        type="text"
                                                        className={classnames("form-control",
                                                        )} />

                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="name">Last Name</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input
                                                        value={this.state.lastname}
                                                        id="user-update-name"
                                                        type="text"
                                                        className={classnames("form-control",
                                                        )} />

                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="name">Personal Identifier Code</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input
                                                        value={this.state.personalIdentifierCode}
                                                        id="user-update-name"
                                                        type="text"
                                                        className={classnames("form-control",
                                                        )} />

                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="name">Date of Birth</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input

                                                        value={this.state.dateOfBirth}
                                                        id="user-update-name"
                                                        type="text"
                                                        className={classnames("form-control",
                                                        )} />

                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="name">Birth Place</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input

                                                        value={this.state.birthplace}
                                                        id="user-update-name"
                                                        type="text"
                                                        className={classnames("form-control",
                                                        )} />

                                                </div>

                                            </div>
                                            <br></br>
                                            <p className="form_val_text">Address</p>
                                            <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="name">Street</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input

                                                        value={this.state.street}
                                                        id="user-update-name"
                                                        type="text"
                                                        className={classnames("form-control",
                                                        )} />

                                                </div>
                                            </div> <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="name">City</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input
                                                        value={this.state.city}
                                                        id="user-update-name"
                                                        type="text"
                                                        className={classnames("form-control",
                                                        )} />

                                                </div>
                                            </div> <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="name">Country</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input

                                                        value={this.state.country}
                                                        id="user-update-name"
                                                        type="text"
                                                        className={classnames("form-control",
                                                        )} />

                                                </div>

                                            </div>
                                            <br></br>
                                            <p className="form_val_text">Residency Address</p>
                                            <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="name">Street</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input

                                                        value={this.state.residencystreet}
                                                        id="user-update-name"
                                                        type="text"
                                                        className={classnames("form-control",
                                                        )} />

                                                </div>
                                            </div> <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="name">City</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input
                                                        value={this.state.residencycity}
                                                        id="user-update-name"
                                                        type="text"
                                                        className={classnames("form-control",
                                                        )} />

                                                </div>
                                            </div> <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="name">Country</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input

                                                        value={this.state.residencycountry}
                                                        id="user-update-name"
                                                        type="text"
                                                        className={classnames("form-control",
                                                        )} />

                                                </div>
                                            </div>
                                            <br></br>
                                            <h6 className="text">Financial Information</h6>
                                            <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="name">Current Occupation</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input

                                                        value={this.state.currentOccupation}
                                                        id="user-update-name"
                                                        type="text"
                                                        className={classnames("form-control",
                                                        )} />

                                                </div>
                                            </div>

                                            {this.state.currentOccupation && this.state.currentOccupation === "Employee" ?
                                                <>
                                                    <div className="row mt-2">
                                                        <div className="col-md-3">
                                                            <label htmlFor="name">Function</label>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <input

                                                                value={this.state.currentOccupation}
                                                                id="user-update-name"
                                                                type="text"
                                                                className={classnames("form-control",
                                                                )} />

                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-md-3">
                                                            <label htmlFor="name">Employer Name</label>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <input

                                                                value={this.state.employerName}
                                                                id="user-update-name"
                                                                type="text"
                                                                className={classnames("form-control",
                                                                )} />

                                                        </div>
                                                    </div>
                                                </>
                                                : ""}

                                            {this.state.currentOccupation === "Partner/shareholder/director" ? <>
                                                <div className="row mt-2">
                                                    <div className="col-md-3">
                                                        <label htmlFor="name">Company Name</label>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <input

                                                            value={this.state.companyName}
                                                            id="user-update-name"
                                                            type="text"
                                                            className={classnames("form-control",
                                                            )} />

                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-md-3">
                                                        <label htmlFor="name">Nature of Activity</label>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <input

                                                            value={this.state.natureOfActivity}
                                                            id="user-update-name"
                                                            type="text"
                                                            className={classnames("form-control",
                                                            )} />

                                                    </div>
                                                </div>
                                            </> : ""}

                                            {this.state.currentOccupation === "Independent Professional" ? <>
                                                <div className="row mt-2">
                                                    <div className="col-md-3">
                                                        <label htmlFor="name">Name of Entity</label>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <input

                                                            value={this.state.nameOfEntity}
                                                            id="user-update-name"
                                                            type="text"
                                                            className={classnames("form-control",
                                                            )} />

                                                    </div>
                                                </div>
                                            </> : ""}

                                            {this.state.currentOccupation === "Self-employed" ? <>
                                                <div className="row mt-2">
                                                    <div className="col-md-3">
                                                        <label htmlFor="name">Type of Activity</label>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <input

                                                            value={this.state.typeOfActivity}
                                                            id="user-update-name"
                                                            type="text"
                                                            className={classnames("form-control",
                                                            )} />

                                                    </div>
                                                </div>
                                            </> : ""}

                                            {this.state.currentOccupation === "Other" ?
                                                <>
                                                    <div className="row mt-2">
                                                        <div className="col-md-3">
                                                            <label htmlFor="name">Specify Activity</label>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <input

                                                                value={this.state.specifyActivity}
                                                                id="user-update-name"
                                                                type="text"
                                                                className={classnames("form-control",
                                                                )} />

                                                        </div>
                                                    </div>
                                                </>
                                                : ""}

                                            <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="name">Source of Funds</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input

                                                        value={this.state.sourceOfFunds}
                                                        id="user-update-name"
                                                        type="text"
                                                        className={classnames("form-control",
                                                        )} />

                                                </div>
                                            </div>

                                            {this.state.sourceOfFunds === "Other sources of income. Please give details:" ?
                                                <>
                                                    <div className="row mt-2">
                                                        <div className="col-md-3">
                                                            <label htmlFor="name">Other Sources of Income</label>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <input

                                                                value={this.state.othersourcesofincome}
                                                                id="user-update-name"
                                                                type="text"
                                                                className={classnames("form-control",
                                                                )} />

                                                        </div>
                                                    </div>
                                                </>
                                                : ""}
                                            <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="name">Net Monthly Income</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input

                                                        value={this.state.monthlyIncome}
                                                        id="user-update-name"
                                                        type="text"
                                                        className={classnames("form-control",
                                                        )} />

                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="name">Purpose and nature of the business relationship</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input
                                                        value={this.state.businessRelationship}
                                                        id="user-update-name"
                                                        type="text"
                                                        className={classnames("form-control",
                                                        )} />

                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="name">Estimated annual volume to be traded</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input

                                                        value={this.state.estimatedAnnualVolume}
                                                        id="user-update-name"
                                                        type="text"
                                                        className={classnames("form-control",
                                                        )} />

                                                </div>
                                            </div>
                                            <br></br></>
                                        : <>
                                            <h5 className="text">Entity Verification</h5><br></br>
                                            <h6 className="text">Company Registration Details</h6>
                                            <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="name">Company Name</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input

                                                        value={this.state.companyname}
                                                        id="user-update-name"
                                                        type="text"
                                                        className={classnames("form-control",
                                                        )} />

                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="name">Certificate of Incorporation/Registration Number</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input
                                                        value={this.state.regno}
                                                        id="user-update-name"
                                                        type="text"
                                                        className={classnames("form-control",
                                                        )} />

                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="name">Registration Date</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input
                                                        value={this.state.regdate}
                                                        id="user-update-name"
                                                        type="text"
                                                        className={classnames("form-control",
                                                        )} />

                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="name">Jurisdiction of Incorporation/Registration</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input

                                                        value={this.state.registration}
                                                        id="user-update-name"
                                                        type="text"
                                                        className={classnames("form-control",
                                                        )} />

                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="name">Headquarter/Address</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input

                                                        value={this.state.address}
                                                        id="user-update-name"
                                                        type="text"
                                                        className={classnames("form-control",
                                                        )} />

                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="name">Sector/Industry/Business Type</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input
                                                        value={this.state.businesstype}
                                                        id="user-update-name"
                                                        type="text"
                                                        className={classnames("form-control",
                                                        )} />

                                                </div>
                                            </div>
                                            <br></br>
                                            <h6 className="text">Legal representative of the Company</h6>
                                            <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="name">Full Name</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input
                                                        value={this.state.fullname}
                                                        id="user-update-name"
                                                        type="text"
                                                        className={classnames("form-control",
                                                        )} />

                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="name">Personal ID Number</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input
                                                        value={this.state.personalIdNo}
                                                        id="user-update-name"
                                                        type="text"
                                                        className={classnames("form-control",
                                                        )} />

                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="name">Street</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input
                                                        value={this.state.legalStreet}
                                                        id="user-update-name"
                                                        type="text"
                                                        className={classnames("form-control",
                                                        )} />

                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="name">City</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input
                                                        value={this.state.legalCity}
                                                        id="user-update-name"
                                                        type="text"
                                                        className={classnames("form-control",
                                                        )} />

                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="name">Country</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input
                                                        value={this.state.legalCountry}
                                                        id="user-update-name"
                                                        type="text"
                                                        className={classnames("form-control",
                                                        )} />

                                                </div>
                                            </div>
                                            <br></br>
                                            <h6 className="text">Company Financial Details</h6>
                                            <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="name">Source of Funds</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input
                                                        value={this.state.companySourceOfFunds}
                                                        id="user-update-name"
                                                        type="text"
                                                        className={classnames("form-control",
                                                        )} />

                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="name">Annual Turnover</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input
                                                        value={this.state.companyAnnualTurnover}
                                                        id="user-update-name"
                                                        type="text"
                                                        className={classnames("form-control",
                                                        )} />

                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="name">Purpose and nature of the business relationship</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input
                                                        value={this.state.companyBusinessRelationship}
                                                        id="user-update-name"
                                                        type="text"
                                                        className={classnames("form-control",
                                                        )} />

                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="name">Estimated annual volume to be traded</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input
                                                        value={this.state.companyAnnualVolume}
                                                        id="user-update-name"
                                                        type="text"
                                                        className={classnames("form-control",
                                                        )} />

                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="name">Name</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input
                                                        value={this.state.businessPartnerName}
                                                        id="user-update-name"
                                                        type="text"
                                                        className={classnames("form-control",
                                                        )} />

                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="name">Country where the partner is registered</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input
                                                        value={this.state.partnerCountry}
                                                        id="user-update-name"
                                                        type="text"
                                                        className={classnames("form-control",
                                                        )} />

                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="name">Business Activity</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input
                                                        value={this.state.businessActivity}
                                                        id="user-update-name"
                                                        type="text"
                                                        className={classnames("form-control",
                                                        )} />

                                                </div>
                                            </div>

                                        </>
                                    }

                                    {/* <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="email">Email</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.email}
                                                error={errors.email}
                                                id="user-update-email"
                                                type="email"
                                                className={classnames("form-control", {
                                                    invalid: errors.email
                                                })}
                                            />
                                           
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password">Password</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                data-reset-input={true}
                                                autoComplete={''}
                                                onChange={this.onChange}
                                                error={errors.password}
                                                id="user-update-password"
                                                type="password"
                                                className={classnames("form-control", {
                                                    invalid: errors.password
                                                })}
                                            />
                                        </div>
                                    </div> */}
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    className="btn btn-primary" onClick={()=>{this.getverifystatus("FINISHED")}}>
                                    Approve
                                </button>
                                <button
                                    className="btn btn-secondary"  onClick={()=>{this.getverifystatus("REJECTED")}}>
                                    Reject
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

KycViewModal.propTypes = {
    updateUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateUser }
)(withRouter(KycViewModal));
