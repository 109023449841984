import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import ContactUpdateModal from "../partials/ContactUpdateModal";
import { toast, ToastContainer} from "react-toastify";
import keys from "../../actions/config";
const url = keys.baseUrl;
class Contactus extends Component {
    constructor(props) {
        super(props);

        this.columns = [
            
            {
                key: "name",
                text: "Name",
                className: "name",
                align: "left",
                sortable: true,
            },
            {
                key: "email",
                text: "Email",
                className: "email",
                align: "left",
                sortable: true
            },
            {
                key: "message",
                text: "Message",
                className: "message",
                align: "left",
                sortable: true
            },
            {
                key: "replystatus",
                text: "Reply Status",
                className: "message",
                align: "left",
                sortable: true
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <button
                                data-toggle="modal"
                                data-target="#update-contact-modal"
                                className="btn btn-primary btn-sm mr-2"
                                onClick={() => this.editRecord(record)}
                                >
                                <i className="fa fa-reply"></i>
                            </button>
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRecord(record)}>
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Cms",
            no_data_text: 'No Enquires found!',
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: []
        };

        this.state = {
            totalCount:0,
            currentRecord: {
                _id: '',
                name: '',
                email: '',
                mobilenumber: '',
                message: '',
                reply : ''
            }
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
        // var pagelinkofset = document.querySelectorAll(".list-group-item-action.active")[0].offsetTop;
        // document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset);
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData() {
        const data = {
            limit :10,
            page : 1
          }
        axios
            .post(url+"api/contact-data", data)
            .then(res => {
                console.log(res,'res');
                this.setState({ records: res.data.data, totalCount: res.data.count})
            })
            .catch()
    }

    editRecord(record) {
        console.log(record,'record');
        this.setState({ currentRecord: record});
    }

    deleteRecord(record) {
      console.log(record);
        axios
            .post(url+"api/contact-delete", {_id: record._id})
            .then(res => {
                if (res.status === 200) {
                   toast(res.data.message, {
                       position: toast.POSITION.TOP_CENTER,
                   })
                }
            })
            .catch();
        this.getData();
    }

   async  pageChange(pageData) {
        const data = {
            limit: pageData.page_size,
            page: pageData.page_number,
            search: pageData.filter_value
        }
        const getData = await axios.post(url + "api/contact-data", data);
        this.setState({ records: getData.data.data, totalCount: getData.data.count });

        console.log("OnPageChange_OnPageChange", pageData);
    }

    render() {
        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    {/* onHide={()=>{console.log(this.state.currentRecord,"onHide");this.setState({currentRecord:{}})}} */}
                    <ContactUpdateModal record={this.state.currentRecord} />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h3 className="mt-2 text-secondary">Enquires List</h3>
                            <div className="resonsive_table">
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                dynamic={true}
                                total_record={this.state.totalCount}
                                onChange={this.pageChange.bind(this)}
                                className="table table-bordered table-striped contact_table"
                            />
                            </div>
                        </div>
                    </div>
                    <ToastContainer/>
                </div>
            </div>
        );
    }

}

Contactus.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(Contactus);
